<span class="badge yellow-badge" *ngIf="statusValue === statusEnum.Pending">
  <span class="circle-yellow"></span>
  {{ 'pending' | translate }}
</span>
<span class="badge green-badge" *ngIf="statusValue === statusEnum.Confirmed">
  <span class="circle-green"></span>
  {{ 'confirmed' | translate }}
</span>
<span class="badge blue-badge" *ngIf="statusValue === statusEnum.PickingUp">
  <span class="circle-blue"></span>
  {{ 'picking_up' | translate }}
</span>
<span class="badge blue-badge" *ngIf="statusValue === statusEnum.InProgress">
  <span class="circle-blue"></span>
  {{ 'in_progress' | translate }}
</span>
<span class="badge blue-badge" *ngIf="statusValue === statusEnum.Delivering">
  <span class="circle-blue"></span>
  {{ 'delivering' | translate }}
</span>
<span class="badge black-badge" *ngIf="statusValue === statusEnum.Finished">
  <span class="circle-black"></span>
  {{ 'finished' | translate }}
</span>
<span class="badge red-badge" *ngIf="statusValue === statusEnum.CancelledByCafler">
  <span class="circle-red"></span>
  {{ 'cancelled_by_cafler' | translate }}
</span>
<span class="badge red-badge" *ngIf="statusValue === statusEnum.CancelledByClient">
  <span class="circle-red"></span>
  {{ 'cancelled_by_client' | translate }}
</span>
<span class="badge purple-badge" *ngIf="statusValue === statusEnum.Initialized">
  <span class="circle-purple"></span>
  {{ 'initialized' | translate }}
</span>
