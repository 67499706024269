import { Component, Input } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';

@Component({
  selector: 'app-summary-funnel-dialog',
  templateUrl: './summary-funnel-dialog.component.html',
  styleUrls: ['./summary-funnel-dialog.component.scss'],
})
export class SummaryFunnelDialogComponent {
  @Input() summaryData: SummaryData = {} as SummaryData;
  constructor() {
    console.log(this.summaryData);
  }
}
