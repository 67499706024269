import { ToppingDictionary } from '../interfaces/Topping';

export const myToppingDictionary: ToppingDictionary = {
  'topping-insurance': {
    id: 'insurance',
    icon: 'assets/icons/insurance-during-service.svg',
    titleId: 'insurance',
    descriptionId: 'insuranceDescription',
    showStartingFrom: true,
    hasModal: true,
  },
  'topping-refueling': {
    id: 'refueling',
    icon: 'assets/icons/refuel.svg',
    titleId: 'refueling',
    descriptionId: 'refuelingDescription',
    showStartingFrom: true,
    hasModal: true,
  },
  'topping-replacement-vehicle': {
    id: 'replacement_vehicle',
    icon: 'assets/icons/sustitution-vehicle.svg',
    titleId: 'replacementVehicle',
    descriptionId: 'replacementVehicleDescription',
    showStartingFrom: true,
    hasModal: true,
  },
  'topping-washes': {
    id: 'wash',
    icon: 'assets/icons/lavados-icon.svg',
    titleId: 'wash',
    descriptionId: 'washDescription',
    showStartingFrom: true,
    hasModal: true,
    relatedToppings: {
      'specialized-center-wash': {
        id: 'specialized-center-wash',
        icon: 'assets/icons/lavados-icon.svg',
        titleId: 'specialized-center-wash',
        descriptionId: 'specializedCenterWashDescription',
        showStartingFrom: true,
        relatedToppings: {
          'interior-car-wash-topping': {
            id: 'interior-car-wash-topping',
            icon: 'assets/icons/center-interior.svg',
            titleId: 'Interior',
            descriptionId:
              'Deja que tu vehículo respire frescura. Con nuestra limpieza interior, eliminamos la suciedad y los olores, ofreciéndote un habitáculo impecable.',
            showStartingFrom: true,
            hasModal: true,
          },
          'exterior-car-wash-topping': {
            id: 'exterior-car-wash-topping',
            icon: 'assets/icons/center-exterior.svg',
            titleId: 'Exterior',
            descriptionId:
              'Dale a tu vehículo el brillo que se merece. Nuestro lavado exterior utiliza agua y rodillos para dejar tu vehículo reluciente en minutos.',
            showStartingFrom: true,
          },
          'complete-car-wash-topping': {
            id: 'complete-car-wash-topping',
            icon: 'assets/icons/center-complete.svg',
            titleId: 'Completo',
            descriptionId:
              'Experimenta la limpieza total. Con nuestro servicio completo, tu vehículo recibe un tratamiento integral, tanto por dentro como por fuera, para un resultado impecable.',
            showStartingFrom: true,
            hasModal: true,
          },
          'motorbike-wash-topping': {
            id: 'motorbike-wash-topping',
            icon: 'assets/icons/center-complete.svg',
            titleId: 'Completo',
            descriptionId:
              'Experimenta la limpieza total. Con nuestro servicio completo, tu vehículo recibe un tratamiento integral para un resultado impecable.',
            showStartingFrom: true,
            hasModal: true,
          },
        },
      },
      'tunnel-wash': {
        id: 'tunnel-wash',
        icon: 'assets/icons/lavados-icon.svg',
        titleId: 'tunnel-wash',
        descriptionId: 'handWashDescription',
        showStartingFrom: true,
        relatedToppings: {
          'tunnel-exterior-car-wash-topping': {
            id: 'tunnel-exterior-car-wash-topping',
            icon: 'assets/icons/hand-exterior.svg',
            titleId: 'Lavado exterior en túnel',
            descriptionId:
              'Servicio básico en túnel de gasolinera diseñado para eliminar incrustaciones y suciedad acumulada durante el viaje.',
            showStartingFrom: true,
          },
        },
      },
    },
  },
  'topping-vehicle-data-collection': {
    id: 'checkStatusVehicle',
    icon: 'assets/icons/check-status.svg',
    titleId: 'checkStatusVehicle',
    descriptionId: 'checkStatusVehicleDescription',
    showStartingFrom: true,
    hasModal: false,
  },
  'topping-tyres': {
    id: 'tyres',
    icon: 'assets/icons/tyres.svg',
    titleId: 'checkTyres',
    descriptionId: 'checkTyresDescription',
    showStartingFrom: true,
  },
  'topping-accesory': {
    id: 'accesory',
    icon: 'assets/icons/cafler-fresh.svg',
    titleId: 'caflerFresh',
    descriptionId: 'caflerFreshDescription',
    showStartingFrom: true,
  },
};

export const washToppingDictionary: ToppingDictionary = {};
