import { Injectable } from '@angular/core';
import { IProduct } from '../interfaces/product.interface';
import { products } from '../data/products';

@Injectable({
  providedIn: 'root',
})
export class InfoModalService {
  private products: { [key: string]: IProduct } = products;

  public getProduct(key: string): IProduct | null {
    return this.products[key] || null;
  }
}
