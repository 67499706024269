export interface ChassisType {
  description: string;
  icon: string;
}

export const chassisType: { [key: number]: ChassisType } = {
  1: { description: 'tourism', icon: '/assets/coche-productos.svg' },
  2: { description: 'minivan', icon: '/assets/coche-productos.svg' },
  3: { description: 'atv', icon: '/assets/coche-productos.svg' },
  4: { description: 'light-van', icon: 'assets/icons/van-icon.svg' },
  5: { description: 'van', icon: 'assets/icons/van-icon.svg' },
  6: { description: 'box-truck', icon: 'assets/icons/van-icon.svg' },
  20: { description: 'Moto (hasta 125CC)', icon: 'assets/icons/motorcycle-icon.svg' },
  21: { description: 'Moto +125CC', icon: 'assets/icons/motorcycle-icon.svg' },
};

export const chassisTypeSimple: { [key: number]: string } = {
  1: 'tourism',
  2: 'minivan',
  3: 'atv',
  4: 'light-van',
  5: 'van',
  6: 'box-truck',
  20: 'Moto (hasta 125CC)',
  21: 'Moto +125CC',
};
