<div class="d-flex flex-column">
  <div class="section-container section-divider gap-30 d-flex flex-column">
    <div class="d-flex flex-column gap-15">
      <div class="d-flex flex-row justify-content-end align-items-center">
        <img
          src="../../../../../assets/icons/close-modal.svg"
          alt="Close icon"
          class="icon-size"
          (click)="onClose()"
          (keyup)="onClose()"
          tabindex="0"
        />
      </div>
      <span class="title" *ngIf="currentIsFromMyServicesStep === 0">{{
        'return_transfer' | translate
      }}</span>
      <span *ngIf="currentIsFromMyServicesStep === 0">{{
        'return_transfer_description' | translate
      }}</span>
      <span class="title" *ngIf="currentIsFromMyServicesStep !== 0">{{
        'return_transfer_title' | translate
      }}</span>
    </div>
    <app-stepper
      [steps]="isFromMyServicesSteps"
      [activeStep]="currentIsFromMyServicesStep"
      [isVertical]="true"
      *ngIf="data.isFromMyServices"
    ></app-stepper>
    <ng-container *ngIf="currentIsFromMyServicesStep === 0">
      <div class="disclaimer d-flex flex-row">
        <img [src]="'/assets/icons/information-circle.svg'" alt="Info icon" class="icon-size" />
        <span [innerHTML]="'return_transfer_disclaimer' | translate"></span>
      </div>
      <div class="address-container">
        <span class="route-title">{{ 'your_route' | translate }}</span>
        <app-address-box [summaryData]="swappedSummaryData" [routeOnly]="true"></app-address-box>
      </div>
      <div class="d-flex gap-15 flex-column">
        <h4 class="d-flex align-items-center sub-title">
          <img
            src="../../../../../assets/icons/new-service-calendar.svg"
            alt="Calendar icon"
          />&nbsp;
          {{ 'pickupDateAndTime' | translate }}
        </h4>

        <div class="summary-information-box gap-10 d-flex flex-column">
          <label for="pickup-date" class="bold-sm">{{ 'pickup_day' | translate }}</label>
          <div class="calendar-picker-container w-100">
            <input
              type="text"
              class="form-control calendar-picker"
              #dp="bsDatepicker"
              bsDatepicker
              [daysDisabled]="[6, 0]"
              [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-default',
                minDate: minDate,
              }"
              autocomplete="off"
              (bsValueChange)="onPickupDateChange($event)"
              required
              placeholder="DD/MM/YYYY"
            />
            <div class="calendar-icon"></div>
          </div>
        </div>
        <div
          class="pickup-hour gap-15 d-flex flex-column"
          *ngIf="pickupDateSelected && !isTransit()"
        >
          <div class="summary-information-box gap-10 fw-600 d-flex flex-column">
            <label for="pickup-date" class="bold-sm">{{ 'pickupTime' | translate }}</label>
            <select
              class="select-container w-100 padding-left"
              name="pickup-date"
              id="pickup-date"
              [(ngModel)]="isSpecificHours"
              (ngModelChange)="onTimeSlotChange($event)"
            >
              <option [ngValue]="false">{{ 'scheduleWithTimeSlots' | translate }}</option>
              <option [ngValue]="true">
                {{ 'scheduleWithSpecificTimesPrice1' | translate }}
              </option>
            </select>
          </div>

          <div *ngIf="noAvailableHours" class="no-hours-message">
            {{ 'noAvailableHoursMessage' | translate }}
          </div>

          <div
            class="d-flex flex-column justify-content-between gap-15"
            *ngIf="!isSpecificHours && !noAvailableHours"
          >
            <div class="d-flex justify-content-between w-100 gap-15">
              <div class="summary-information-box gap-10 fw-600 d-flex flex-column w-100">
                <label for="pickupTime" class="bold-sm">{{ 'from' | translate }}</label>
                <div class="time-selector gap-10 d-flex">
                  <div class="select-container d-flex flex-column align-items-center w-100">
                    <div class="clock"></div>
                    <select
                      name="pickupTime"
                      id="pickupTime"
                      [(ngModel)]="selectedStartHour"
                      (ngModelChange)="handleStartTimeChange($event)"
                      class="custom-select d-flex"
                      required
                    >
                      <option *ngFor="let hour of availableStartHours" [ngValue]="hour">
                        {{ hour.Text }}
                      </option>
                    </select>
                    <div class="chevron"></div>
                  </div>
                </div>
              </div>

              <div class="summary-information-box gap-10 fw-600 d-flex flex-column w-100">
                <label for="endHour" class="bold-sm">{{ 'until' | translate }}</label>
                <div
                  class="time-selector gap-10 w-100 d-flex"
                  [ngClass]="{ 'gray-bg': noAvailableEndHours }"
                >
                  <div class="select-container d-flex flex-column align-items-center w-100">
                    <div class="clock"></div>
                    <select
                      name="endHour"
                      id="endHour"
                      [(ngModel)]="selectedEndHour"
                      (ngModelChange)="updateSelectedHours($event)"
                      class="custom-select"
                    >
                      <option *ngFor="let hour of availableEndHours" [ngValue]="hour">
                        {{ hour.Text }}
                      </option>
                    </select>
                    <div class="chevron"></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="noAvailableEndHours" class="warning-message no-hours-message">
              {{ warningMessage }}
            </div>
          </div>

          <div class="time-selector gap-10 w-100" *ngIf="isSpecificHours && !noAvailableHours">
            <div class="custom-select-container">
              <button class="custom-select custom-select-hour" (click)="toggleDropdown()">
                {{ selectedHourPair ? selectedHourPair.displayText : 'Selecciona una hora' }}
              </button>
              <div class="custom-options" [ngClass]="{ show: showDropdown }">
                <div
                  *ngFor="let hour of availableHours"
                  [ngClass]="{ selected: hour === selectedHourPair }"
                  (click)="selectHour(hour)"
                  (keydown)="selectHour(hour)"
                  tabindex="0"
                  class="custom-option"
                >
                  {{ hour.displayText }}
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex urgency-fee-disclaimer">
            <img src="/assets/icons/shield-exclamation.svg" class="icon-size" alt="shield" />
            <span>{{ 'urgency_fee_disclaimer' | translate }}</span>
          </div>
        </div>
      </div>

      <form [formGroup]="form">
        <h4 class="d-flex align-items-center justify-content-start gap-10">
          <img src="../../../../../assets/icons/new-service-chat.svg" alt="" />
          <span class="sub-title">{{ 'additionalInstructions' | translate }}</span>
          <small class="sub-title">({{ 'optional' | translate }})</small>
        </h4>
        <div class="d-flex flex-column w-100">
          <textarea
            [formControlName]="'extraInfo'"
            placeholder="{{ 'write_here' | translate }}"
            class="inst-adicional d-flex flex-column align-items-end justify-content-end"
            (keydown)="onKeyDown($event)"
            maxlength="{{ maxChars }}"
          ></textarea>
          <p class="d-flex w-100 justify-content-end align-items-center gap-10 m-0">
            {{ charCount() }}
          </p>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="currentIsFromMyServicesStep === 1">
      <app-extras-funnel-dialog
        [summaryData]="swappedSummaryData"
        [mappedToppings]="mappedToppings"
      ></app-extras-funnel-dialog>
    </ng-container>

    <ng-container *ngIf="currentIsFromMyServicesStep === 2">
      <app-summary-funnel-dialog [summaryData]="swappedSummaryData"></app-summary-funnel-dialog>
    </ng-container>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center button-container">
    <button
      *ngIf="currentIsFromMyServicesStep > 0"
      class="btn btn-secondary btn-lg mr-2 back-button"
      (click)="onBack()"
    >
      {{ 'back' | translate }}
    </button>
    <button
      class="btn btn-primary btn-lg btn-block"
      [ngClass]="{ 'w-100': currentIsFromMyServicesStep === 0 }"
      (click)="onSubmit()"
    >
      {{
        currentIsFromMyServicesStep === 2
          ? ('add_return_to_origin' | translate)
          : ('continue' | translate)
      }}
    </button>
  </div>
</div>
