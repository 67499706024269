import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private showAlertSubject = new BehaviorSubject<boolean>(false);
  showAlert$ = this.showAlertSubject.asObservable();

  setShowAlert(value: boolean) {
    this.showAlertSubject.next(value);
    localStorage.setItem('showUpsertVehicleAlert', JSON.stringify(value));
  }

  shouldShowAlert(): boolean {
    const storedValue = localStorage.getItem('showUpsertVehicleAlert');
    return storedValue === 'true';
  }

  resetAlert() {
    this.showAlertSubject.next(false);
    localStorage.removeItem('showUpsertVehicleAlert');
  }
}