<div class="address-box">
  <div class="address-box-img">
    <img src="/assets/icons/gps-icon.svg" alt="gps icon" class="location-icon" />
    <img src="/assets/icons/active-routes-adress-line.svg" alt="address Line" class="line-icon" />
    <img src="/assets/icons/gps-icon.svg" alt="gps icon" class="location-icon" />
  </div>
  <div class="address-box-text">
    <div>
      <span class="address-headers">{{ 'pickup' | translate }}</span>
      <div class="d-flex flex-column location-info-container">
        <span class="address-styles">
          {{ summaryData.locationInfo?.originAddress }}
          {{ summaryData.locationInfo?.originAddressDetails }}
        </span>
        <span *ngIf="!routeOnly">{{ handleStartDate() }}</span>
        <span *ngIf="!routeOnly">{{ handleStartTime() }}</span>
      </div>
    </div>
    <div style="width: 100%">
      <span class="address-headers mt-4">{{ 'delivery' | translate }}</span>
      <div class="d-flex flex-column location-info-container" *ngIf="!canChangeDeliveryAddress">
        <span class="address-styles">
          {{ summaryData.locationInfo?.destinationAddress }}
          {{ summaryData.locationInfo?.destinationAddressDetails }}
        </span>
        <span>{{ summaryData.locationInfo?.formattedReturnDate }}</span>
        <span *ngIf="summaryData.locationInfo?.formattedReturnEndTime">
          {{ summaryData.locationInfo?.formattedReturnStartTime }} -
          {{ summaryData.locationInfo?.formattedReturnEndTime }}
        </span>
      </div>
      <div class="d-flex flex-column location-info-container" *ngIf="canChangeDeliveryAddress">
        <div class="form-box mt-2">
          <div class="input-container">
            <div (click)="showCenterSelection()" (keyup.enter)="showCenterSelection()" tabindex="0"
              class="autocompleteInput">
              <img src="assets/icons/new-service-pickup-location.svg" alt="location" class="form-icon" />
              <input [placeholder]="pickUpPlaceHolder" (input)="filterAddresses(inputFieldAutocomplete1.value)"
                #inputFieldAutocomplete1 name="completeLocation" />
              <!-- <input placeholder="Carrer del Comte Borrell, 42, 08015, Barcelona, España"
                (keydown)="initializeAutocomplete()" #inputFieldAutocomplete1 name="completeLocation" /> -->
            </div>
          </div>
          <div class="selection-box-container">
            <div *ngIf="showSelectionBoxSelected" class="selection-box">
              <div *ngFor="let address of filteredAddressesList" (click)="selectUniqueCustomAddress(address)"
                (keyup.enter)="selectUniqueCustomAddress(address)" tabindex="0"
                class="d-flex flex-row align-items-center option-container text-nowrap gap-1">
                <div class="d-flex flex-row align-items-center">
                  <img src="assets/icons/center-icon.svg" alt="location" class="" />
                  <span>{{ address.AddressKey }}</span>
                </div>
                <span class="text-grey text-truncate">{{ address.Address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>