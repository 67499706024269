<ng-container *ngIf="summaryData.locationInfo?.originContactName">
  <hr class="divider" />
  <div class="d-flex flex-column">
    <span class="address-headers">
      {{
        isSameContact() ? ('pickupAndDeliveryContact' | translate) : ('pickupContact' | translate)
      }}
    </span>
    <span>
      {{ summaryData.locationInfo?.originContactName }}
      {{ summaryData.locationInfo?.originContactLastName }}
    </span>
    <span>{{ summaryData.locationInfo?.originContactPhoneNumber }}</span>
    <span>{{ handleIsExternal(summaryData.locationInfo?.isOriginContactExternal) }}</span>
  </div>
</ng-container>
<ng-container *ngIf="!isSameContact()">
  <hr class="divider" />
  <div class="d-flex flex-column">
    <span class="address-headers">{{ 'deliveryContact' | translate }}</span>
    <span>
      {{ summaryData.locationInfo?.destinationContactName }}
      {{ summaryData.locationInfo?.destinationContactLastName }}
    </span>
    <span>{{ summaryData.locationInfo?.destinationContactPhoneNumber }}</span>
    <span>{{ handleIsExternal(summaryData.locationInfo?.isDestinationContactExternal) }}</span>
  </div>
</ng-container>
<hr class="divider" />
