import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader.service';
import {
  ConcatenatedHour,
  Hour,
  HoursAvailabilityResponse,
} from 'src/app/pages/new-service/interfaces/Hour';

import { LocationInfo, SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';

@Injectable({
  providedIn: 'root',
})
export class SharedFunnelService {
  constructor(
    private funnelService: FunnelService,
    private loader: LoaderService,
    private translate: TranslateService
  ) {}

  getProductAvailability(
    productId: string,
    selectedDate: Date,
    isSpecificHours: boolean,
    isReturn = false,
    summaryData?: SummaryData
  ): Observable<any> {
    this.loader.show();
    return new Observable((observer) => {
      this.funnelService
        .getProductAvailability({
          productId,
          selectedDate: this.formatDateTime(selectedDate),
          isSpecificHours: isReturn ? isSpecificHours : isSpecificHours,
        })
        .subscribe({
          next: (response: any) => {
            let hours = response
              .filter((r: any) => !r.Disabled)
              .map((r: HoursAvailabilityResponse) => {
                return { Text: r.Text, Key: r.Key, Fee: r.Fee };
              });
            if (summaryData) {
              const destinationServiceDate =
                summaryData.locationInfo?.serviceEndDate?.split('T')[0];
              const selectedDateString = this.formatDateTime(selectedDate).split('T')[0];

              if (selectedDateString === destinationServiceDate) {
                const [destinationHour, destinationMinute] = (
                  summaryData.locationInfo?.formattedStartTime || ''
                ).split(':');
                const destinationEndTime =
                  parseInt(destinationHour) * 60 + parseInt(destinationMinute);

                hours = hours.filter((hour: any) => {
                  const [hourStr, minuteStr] = hour.Text.split(':');
                  const hourTime = parseInt(hourStr) * 60 + parseInt(minuteStr);
                  return hourTime >= destinationEndTime;
                });
              }
            }

            observer.next(hours);
            observer.complete();
          },
          error: (error) => {
            console.error('Error loading product availability', error);
            observer.error(error);
          },
          complete: () => {
            this.loader.hide();
          },
        });
    });
  }

  formatDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  transformHours(hours: Hour[]): ConcatenatedHour[] {
    const concatenatedHours: ConcatenatedHour[] = [];
    for (let i = 0; i < hours.length; i += 2) {
      if (i + 1 < hours.length) {
        concatenatedHours.push({
          start: hours[i],
          end: hours[i + 1],
          displayText: `${hours[i].Text} - ${hours[i + 1].Text}`,
        });
      }
    }
    return concatenatedHours;
  }

  handleStartTimeChange(
    event: any,
    availableStartHours: HoursAvailabilityResponse[],
    summaryData: SummaryData
  ): {
    availableEndHours: HoursAvailabilityResponse[];
    noAvailableEndHours: boolean;
    selectedEndHour: string;
    warningMessage: string;
  } {
    const startIndex = availableStartHours.findIndex((hour) => hour.Text === event.Text);

    if (startIndex !== -1) {
      const startTime = new Date(`2000-01-01T${event.Text}`);
      const endTime = new Date(startTime.getTime() + 2 * 60 * 60 * 1000);
      const latestEndTime = new Date(`2000-01-01T17:00`);

      const availableEndHours = availableStartHours.filter((hour) => {
        const hourTime = new Date(`2000-01-01T${hour.Text}`);
        return hourTime > endTime && hourTime <= latestEndTime;
      });

      if (availableEndHours.length === 0) {
        if (summaryData.locationInfo?.formattedEndTime) {
          summaryData.locationInfo.formattedEndTime = '';
        }
        return {
          availableEndHours,
          noAvailableEndHours: true,
          selectedEndHour: '',
          warningMessage: this.translate.instant('changePickupTimeOrContactSupport'),
        };
      } else {
        return {
          availableEndHours,
          noAvailableEndHours: false,
          selectedEndHour: availableEndHours[0].Text || '',
          warningMessage: '',
        };
      }
    } else {
      return {
        availableEndHours: [],
        noAvailableEndHours: true,
        selectedEndHour: '',
        warningMessage: '',
      };
    }
  }

  checkDateTime(selectedDate: Date, currentDate: Date = new Date()): boolean {
    currentDate.setHours(0, 0, 0);
    const warningEnd = new Date();
    warningEnd.setDate(currentDate.getDate() + 1);
    warningEnd.setHours(23, 59, 59);

    const dayOfWeekToday = currentDate.getDay();
    const dayOfWeekSelected = selectedDate.getDay();

    const isTodayFriday = dayOfWeekToday === 5;
    const isSelectedDateMonday = dayOfWeekSelected === 1;

    return (
      (selectedDate >= currentDate && selectedDate <= warningEnd) ||
      (isTodayFriday && isSelectedDateMonday)
    );
  }

  handleTimeSlotChange(event: any): LocationInfo {
    return {
      serviceStartDate: event.start.Key,
      serviceEndDate: event.end.Key,
      formattedStartTime: event.start.Text,
      formattedEndTime: event.end.Text,
      fee: event.start.Fee,
    };
  }

  resetTimeSlotSelection(): {
    selectedStartHour: null;
    selectedEndHour: string;
    availableStartHours: HoursAvailabilityResponse[];
    availableEndHours: HoursAvailabilityResponse[];
    selectedHourPair: null;
    noAvailableHours: boolean;
    showDropdown: boolean;
  } {
    return {
      selectedStartHour: null,
      selectedEndHour: '',
      availableStartHours: [],
      availableEndHours: [],
      selectedHourPair: null,
      noAvailableHours: false,
      showDropdown: false,
    };
  }

  // Añade aquí cualquier otro método común que encuentres en ambos componentes
}
