import {
  IPublicClientApplication,
  PublicClientApplication,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

export class MsalConfigService {
  public static createMsalInstance(): IPublicClientApplication {
    function isIE(): boolean {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf('MSIE ');
      const trident = ua.indexOf('Trident/');
      return msie > 0 || trident > 0;
    }

    function loggerCallback(logLevel: LogLevel, message: string, containsPii: boolean): void {
      if (!containsPii) {
        switch (logLevel) {
          case LogLevel.Error:
            console.error(message);
            //reload on error (provisional)
            window.location.reload();
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            console.log(message);
        }
      }
    }

    return new PublicClientApplication({
      auth: {
        clientId: environment.azureActiveDirectory.clientId,
        authority: `${environment.azureActiveDirectory.instance}${environment.azureActiveDirectory.tenant}${environment.azureActiveDirectory.policyName}`,
        knownAuthorities: [`${environment.azureActiveDirectory.instance}`],
        redirectUri: environment.azureActiveDirectory.redirectUri,
        postLogoutRedirectUri: environment.azureActiveDirectory.postLogoutRedirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE(),
      },
      system: {
        loggerOptions: {
          loggerCallback: loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false,
        },
      },
    });
  }

  public static msalInterceptorConfig(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>([
      [
        'https://identity.cafler.com/my-identity/Business.Services',
        ['https://identity.cafler.com/my-identity/Business.Services/read'],
      ],
      [
        'https://identity.cafler.com/my-identity/Business.Identity',
        ['https://identity.cafler.com/my-identity/Business.Identity/read'],
      ],
    ]);
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: protectedResourceMap,
    };
  }

  public static msalGuardConfig(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [
          'https://identity.cafler.com/my-identity/Account.Identity',
          'https://identity.cafler.com/my-identity/Business.Identity',
        ],
      },
    };
  }
}
