import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Output() closeAlertEvent = new EventEmitter<void>();
  @Input() isForDialog = false;
  @Input() text = '';
  @Input() duration = 3000;
  @Input() type = 1;

  timeout: any;

  ngOnInit(): void {
    this.timeout = setTimeout(() => {
      this.closeAlert();
    }, this.duration);
  }

  closeAlert(): void {
    const customAlert = document.querySelector('.alert');

    if (customAlert) {
      customAlert.classList.add('alert-closed');
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.closeAlertEvent.emit();
      }, this.duration);
    }
  }
}
