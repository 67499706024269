import { Injectable } from '@angular/core';
import { Status } from '../../status/enum/Status.enum';
import { MyServicesService } from 'src/app/pages/my-services/services/my-services.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatatableService {
  constructor(private myServicesRequest: MyServicesService) {}

  cancelService(service: string, status: number): Observable<boolean> {
    if (Status.Initialized === status || Status.Pending === status || Status.Confirmed === status) {
      return this.myServicesRequest.cancelService(service).pipe(
        map(() => true),
        catchError((error) => {
          console.error('Error al cancelar el servicio', error);
          return of(false);
        })
      );
    }
    return of(false);
  }
}
