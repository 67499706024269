import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MapData } from './interfaces/MapData.interface';
import { MyAccountService } from 'src/app/pages/my-account/services/my-acount.service';
import * as bootstrap from 'bootstrap';
declare let google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, AfterViewInit {
  private _markers: MapData[] = [];

  @Input()
  set markers(value: MapData[]) {
    this._markers = value;
    if (this.map) {
      this.updateMap();
    }
  }

  get markers(): MapData[] {
    return this._markers;
  }
  @Input() isHome = false;
  @Input() isActiveRoutes = false;
  @Input() isPickUpLocation = false;
  currentPath = window.location.pathname;
  @Output() locationSelected: EventEmitter<void> = new EventEmitter();

  stylesWithStreetLabels = [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
  ];

  stylesWithoutStreetLabels = [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ];

  map: any;
  polyline: any;
  defaultPositionLatitude = 0;
  defaultPositionLongitude = 0;
  private currentMarkers: google.maps.Marker[] = [];

  constructor(private myAccountService: MyAccountService) {}

  ngOnInit(): void {
    this.loadDefaultPosition();
  }

  ngAfterViewInit(): void {
    this.loadDefaultPosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['markers']) {
      if (this.map) {
        this.updateMap();
      }
    }
  }

  private loadDefaultPosition(): void {
    this.myAccountService.getUserDetail().subscribe((data) => {
      this.defaultPositionLatitude = data.DefaultAddressLatitude ?? 41.410924;
      this.defaultPositionLongitude = data.DefaultAddressLongitude ?? 2.1785351;
      this.initMap();
    });
  }

  initMap() {
    const mapStyles =
      this.currentPath === '/new-service'
        ? this.stylesWithStreetLabels
        : this.stylesWithoutStreetLabels;

    const mapElement = document.getElementById('map');
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, {
        center: { lat: this.defaultPositionLatitude, lng: this.defaultPositionLongitude },
        zoom: 13,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        styles: mapStyles,
      });

      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        if (this.markers.length > 0) {
          this.updateMap();
        }

        this.reverseGeocode(this.defaultPositionLatitude, this.defaultPositionLongitude);
      });
    } else {
      console.error('El elemento del mapa no está disponible en el DOM');
    }
  }

  updateMap() {
    if (this.map) {
      this.clearMapElements();
      if (this.markers.length === 0) {
        this.loadDefaultPosition();
        return;
      }
      this.renderMarkers(this.map);
      this.map.panTo({ lat: this.markers[0].lat, lng: this.markers[0].lng });
      if (this.markers.length > 1) {
        if (!this.isActiveRoutes) {
          this.getRouteDirections(this.markers);
        }
        this.fitBounds(this.map);
      }

      this.reverseGeocode(this.markers[0]?.lat, this.markers[0].lng);
    }
  }
  reverseGeocode(lat: any, lng: any) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results: any, status: any) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          let isBusiness = results[0].types.includes('establishment');
          let hasStreetNumber = false;

          addressComponents.forEach((component: any) => {
            if (component.types.includes('street_number')) {
              hasStreetNumber = true;
            }
          });

          if (isBusiness) {
          } else if (!hasStreetNumber) {
            this.locationSelected.emit();
            console.log('La dirección requiere el número o altura de calle.');
          } else {
          }
        } else {
          console.error('No se encontraron resultados.');
        }
      } else {
        console.error('Geocodificación fallida: ' + status);
      }
    });
  }

  private clearMarkers() {
    this.currentMarkers.forEach((marker) => marker.setMap(null));
    this.currentMarkers = [];
    if (this.polyline) {
      this.polyline.setMap(null);
      this.polyline = null;
    }
  }

  renderMarkers(map: any) {
    this.markers.forEach((marker) => {
      const newMarker = this.addMarker(marker, map);
      this.currentMarkers.push(newMarker);
    });
  }

  addMarker(marker: MapData, map: any): google.maps.Marker {
    return new google.maps.Marker({
      position: { lat: marker.lat, lng: marker.lng },
      map: map,
      title: `${marker.licensePlate} ${marker.brand} ${marker.model}`,
      label: {
        text: `${marker.licensePlate} ${marker.brand} ${marker.model}`,
        className: marker.active ? 'marker-label-active' : 'marker-label',
      },
      icon: {
        url: '../../../assets/icons/map-location.svg',
        scaledSize: new google.maps.Size(32, 32),
        anchor: new google.maps.Point(16, 16),
      },
      optimized: false,
    });
  }

  fitBounds(map: any) {
    if (
      this.markers[0].lat === this.markers[1].lat &&
      this.markers[0].lng === this.markers[1].lng
    ) {
      map.setCenter(new google.maps.LatLng(this.markers[0].lat, this.markers[0].lng));
      map.setZoom(15);
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => {
      bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
    });
    map.fitBounds(bounds);
  }

  private clearMapElements() {
    this.currentMarkers.forEach((marker) => marker.setMap(null));
    this.currentMarkers = [];
    if (this.polyline) {
      this.polyline.setMap(null);
      this.polyline = null;
    }
  }

  getRouteDirections(markers: MapData[]) {
    if (this.polyline) {
      this.polyline.setMap(null);
      this.polyline = null;
    }

    const origin = new google.maps.LatLng(markers[0].lat, markers[0].lng);
    const destination = new google.maps.LatLng(markers[1].lat, markers[1].lng);

    const request = {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(request, (response: any, status: any) => {
      if (status === google.maps.DirectionsStatus.OK) {
        const path = response.routes[0].overview_path;
        this.drawPolyline(path);
      } else {
        console.error('Error al obtener las direcciones:', status);
      }
    });
  }

  drawPolyline(path: any[]) {
    if (this.polyline) {
      this.polyline.setMap(null);
    }
    this.polyline = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: '#1e59f5',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    this.polyline.setMap(this.map);
  }
}
