import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationResolver implements Resolve<string> {
  constructor(private translate: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): string {
    const titleKey = route.data['titleKey'];
    return this.translate.instant(titleKey);
  }
}
