import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyServicesService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  baseUrl = environment.tracking_url;

  todayServices(page: number, licensePlate?: string) {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        let url = `${this.baseUrl}/fleet-manager/services/today?page=${page}`;
        if (licensePlate) {
          url += `&licensePlate=${licensePlate}`;
        }
        return this.http.get(url, { headers: { Authorization: 'Bearer ' + token } });
      })
    );
  }

  nextServices(page: number, licensePlate?: string) {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        let url = `${this.baseUrl}/fleet-manager/services/future?page=${page}`;
        if (licensePlate) {
          url += `&licensePlate=${licensePlate}`;
        }
        return this.http.get(url, { headers: { Authorization: 'Bearer ' + token } });
      })
    );
  }

  historyServices(page: number, licensePlate?: string) {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        let url = `${this.baseUrl}/fleet-manager/services/past?page=${page}`;
        if (licensePlate) {
          url += `&licensePlate=${licensePlate}`;
        }
        return this.http.get(url, { headers: { Authorization: 'Bearer ' + token } });
      })
    );
  }

  downloadCsv(serviceRange: string, requestedPage: number) {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        const url = `${this.baseUrl}/fleet-manager/services/${serviceRange}/csv?page=${requestedPage}`;
        return this.http.get(url, {
          headers: { Authorization: 'Bearer ' + token },
          responseType: 'blob',
        });
      })
    );
  }

  downloadSingleCsv(serviceId: string) {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        const url = `${this.baseUrl}/tracking/order/download/details?caflerHash=${serviceId}&lang=es-ES`;
        return this.http.get(url, {
          headers: { Authorization: 'Bearer ' + token },
          responseType: 'blob',
        });
      })
    );
  }

  cancelService(serviceId: string) {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        const url = `${this.baseUrl}/fleet-manager/order?caflerHash=${serviceId}`;
        return this.http.patch(url, {
          headers: { Authorization: 'Bearer ' + token },
        });
      })
    );
  }
}
