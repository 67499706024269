import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private translate: TranslateService) { }

  private sections = [
    {
      title: '',
      children: [
        {
          title: this.translate.instant('home'),
          path: '',
          icon: '../../assets/icons/navbar/nav-home.svg',
        }
      ],
    },
    {
      title: this.translate.instant('management'),
      children: [
        {
          title: this.translate.instant('fleet'),
          path: 'fleets',
          icon: '../../assets/icons/navbar/nav-fleets.svg',
          isNew: false,
        },
        {
          title: this.translate.instant('addresses'),
          path: 'addresses',
          icon: '../../assets/icons/navbar/nav-address.svg',
          isNew: false,
        },
        {
          title: this.translate.instant('employees'),
          path: 'users',
          icon: '../../assets/icons/navbar/nav-users.svg',
          isNew: false,
        },
        {
          title: this.translate.instant('vehicle-storage'),
          path: 'vehicle-storages',
          icon: '../../assets/icons/navbar/nav-campas.svg',
          isNew: true,
        },
      ],
    },
    {
      title: this.translate.instant('activeRoutesVisualization'),
      children: [
        {
          title: this.translate.instant('servicesTitle'),
          path: 'services',
          icon: '../../assets/icons/navbar/nav-services.svg',
          isNew: false,
        },
        {
          title: this.translate.instant('activeRoutes'),
          path: 'active-routes',
          icon: '../../assets/icons/navbar/nav-routes.svg',
          isNew: false,
        },
      ],
    },
    {
      title: this.translate.instant('expenseControl'),
      children: [
        {
          title: this.translate.instant('billingReceipts'),
          path: 'billing',
          icon: '../../assets/icons/navbar/nav-billing.svg',
          isNew: false,
        },
        {
          title: this.translate.instant('reports'),
          path: 'reports',
          icon: '../../assets/icons/navbar/nav-chart-bar.svg',
        },
      ],
    },
    // Más secciones...
  ];

  private accountSections = [
    {
      title: '',
      children: [
        {
          title: this.translate.instant('contactSupport'),
          path: '/',
          icon: '../../assets/icons/navbar/nav-support.svg',
        },
      ],
    },
  ];

  getSections() {
    return this.sections;
  }

  getAccountSections() {
    return this.accountSections;
  }
}
