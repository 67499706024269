import { Component, Input } from '@angular/core';
import { VehicleStatus } from './enum/VehicleStatus.enum';

@Component({
  selector: 'app-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.scss'],
})
export class VehicleStatusComponent {
  @Input() statusValue = 0;
  vhicleStatusEnum = VehicleStatus;
}
