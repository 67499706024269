import { Component, EventEmitter, Output, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NavigationService } from './services/navigation.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

  isHovered = false; // Controla el hover
  isClickBlocked = false; // Bloquea el hover tras el click
  isCollapsed = false;
  @Output() isCollapsedEvent = new EventEmitter<boolean>(false);

  sections: any[];
  accountSections: any[];

  userName = 'Test User';
  userEmail = 'test@gmail.com';
  userImg = '../../assets/icons/navbar/nav-user-icon.svg';

  private userSubscription!: Subscription;

  constructor(
    private navService: NavigationService,
    private userService: UserService,
    private router: Router
  ) {
    this.sections = this.navService.getSections();
    this.accountSections = this.navService.getAccountSections();
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.getUserInfo().subscribe((user) => {
      if (user) {
        this.userName = user.name;
        this.userEmail = user.email;
        this.userImg = user.img;
      } else {
        this.userName = 'Guest User';
        this.userEmail = 'No Email Provided';
        this.userImg = '../../assets/icons/navbar/nav-user-icon.svg';
      }
    });

    // Check initial screen size
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (window.innerWidth <= 1255) {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = false;
    }
    this.isCollapsedEvent.emit(this.isCollapsed);
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsedEvent.emit(this.isCollapsed);
  }

  showNavigation() {
    if (!this.isClickBlocked) {
      this.isHovered = true;
    }
  }

  hideNavigation() {
    this.isHovered = false;
  }

  resetHoverState() {
    // Bloquea el estado hover brevemente tras el click
    this.isClickBlocked = true;
    setTimeout(() => {
      this.isClickBlocked = false;
    }, 300); // Ajusta el tiempo para coincidir con tu animación CSS
  }

  checkSamePath(subSectionPath: string): boolean {
    const actualPath = this.router.url;
    const segments = actualPath.split('/');
    const lastSegment = segments[segments.length - 1];
    return lastSegment === subSectionPath;
  }

  clearSummaryData() {
    localStorage.removeItem('SummaryData');
    localStorage.removeItem('returnTransferSummaryData');
  }
}
