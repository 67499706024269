export const serviceType: { [key: number]: string; } = {
  1: 'Transfer',
  2: 'Taller y mantenimiento',
  3: 'wash',
  4: 'refueling',
  5: 'Pre-ITV',
  6: 'medium_large_transfer',
  7: 'ITV',
  8: 'Seguro',
  9: 'checkStatusVehicle',
  10: 'Cafler Fresh',
  11: 'Valet',
  12: 'Neumáticos',
  13: 'Trámites',
  14: 'Parking',
  15: 'Vehículo de sustitución',
  16: 'Grúa',
  17: 'Pide lo que necesites',
  18: 'Campa'
};
