import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-minimum-conditions',
  templateUrl: './modal-minimum-conditions.component.html',
})
export class ModalMinimumConditionsComponent {
  minimumRequirements = [
    {
      icon: '/assets/icons/new-service-modal-minimum-requirements-1.svg',
      text: 'minimumRequirementsItem1',
    },
    {
      icon: '/assets/icons/new-service-modal-minimum-requirements-2.svg',
      text: 'minimumRequirementsItem2',
    },
    {
      icon: '/assets/icons/new-service-modal-minimum-requirements-3.svg',
      text: 'minimumRequirementsItem3',
    },
  ];
}
