<div class="contact-modal">
  <div class="modal-exit">
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="content">
    <div class="contact-box">
      <h5>
        <img src="../../../../../assets/icons/phone-outgoing.svg" alt="" />
        {{ 'contactUs' | translate }}
      </h5>
      <p>{{ 'callUs' | translate }}</p>
    </div>
    <p class="phone-box">
      <span class="title">{{ 'customerServiceNumber' | translate }}</span>
      <span class="phone">+34 91 290 12 24</span>
      <span class="title">{{ 'email_address' | translate }}:</span>
      <span class="phone">soporte&#64;cafler.com</span>
      <span class="date">{{ 'workingHours' | translate }}</span>
    </p>
  </div>
</div>