import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  loading = false;
  private subscription: Subscription = new Subscription();

  constructor(private loader: LoaderService) {}

  ngOnInit(): void {
    this.subscription = this.loader.getLoading().subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
