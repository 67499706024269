export const NAME_DICTIONARY: { [key: string]: string } = {
  'hand-wash': 'handWash',
  'specialized-center-wash': 'specialized_center_wash',
  'interior-car-wash-topping': 'interiorCarWash',
  'complete-car-wash-topping': 'completeCarWash',
  'exterior-car-wash-topping': 'exteriorCarWash',
  'hand-wash-interior-car-wash-topping': 'interiorSpecializedCarWash',
  'hand-wash-complete-car-wash-topping': 'completeSpecializedCarWash',
  'hand-wash-exterior-car-wash-topping': 'exteriorSpecializedCarWash',
  'interior-minivan-wash-topping': 'interiorVanWash',
  'complete-minivan-wash-topping': 'completeVanWash',
  'exterior-minivan-wash-topping': 'exteriorVanWash',
  'hand-wash-interior-minivan-wash-topping': 'interiorSpecializedVanWash',
  'hand-wash-complete-minivan-wash-topping': 'completeSpecializedVanWash',
  'hand-wash-exterior-minivan-wash-topping': 'exteriorSpecializedVanWash',
  'hand-wash-motorbike-wash-topping': 'specializedMotorcycleWash',
  'insurance-car-topping': 'insurance',
  'insurance-minivan-topping': 'insurance',
  'insurance-motorbike-topping': 'insurance',
  insurance: 'insurance',
  'replacement-vehicle-basic': 'replacementVehicle',
  'topping-replacement-vehicle': 'replacementVehicle',
  'checklist-car-topping': 'checkStatusVehicle',
  'checklist-minivan-topping': 'checkStatusVehicle',
  'checklist-motorbike-topping': 'checkStatusVehicle',
  'tireInflation-car-topping': 'checkTyres',
  'tireInflation-motorbike-topping': 'checkTyres',
  'tireInflation-minivan-topping': 'checkTyres',
};
