import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
  HostListener,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AddressesService } from 'src/app/pages/addresses/services/addresses.service';
import { Address } from 'src/app/pages/new-service/interfaces/Address';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';

export interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-address-box',
  templateUrl: './address-box.component.html',
  styleUrls: ['./address-box.component.scss'],
})
export class AddressBoxComponent {
  @ViewChild('inputFieldAutocomplete1') inputFieldElementRef1!: ElementRef;
  @ViewChild('inputPickUpField', { static: false }) inputPickUpFieldElementRef!: ElementRef;
  @ViewChild('inputField') inputFieldElementRef!: ElementRef;
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() routeOnly = false;
  @Input() canChangeDeliveryAddress = false;
  @Output() deliveryAddressChanged = new EventEmitter<SummaryData>();

  autocompleted1_ok = false;
  autocompleteSingleLocation1: google.maps.places.Autocomplete | undefined;

  pickUpPlaceHolder: string;
  showSelectionBoxSelected = false;
  addressesList: any;
  filteredAddressesList: any[] = [];

  formattedDateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  constructor(private ngZone: NgZone,
    private addressesService: AddressesService,
    private loader: LoaderService,
    private translate: TranslateService) {
    this.getAddressesList();
    this.pickUpPlaceHolder = this.translate.instant('delivery_placeholder');
  }

  ngAfterViewInit() {
    this.initializeAutocomplete();
  }

  handleStartDate(): string {
    if (this.summaryData.locationInfo?.serviceStartDate)
      return this.summaryData.locationInfo?.formattedStartDate as string;
    if (this.summaryData.serviceConfiguration?.appointmentDate)
      return new Date(
        this.summaryData.serviceConfiguration?.appointmentDate as string
      ).toLocaleDateString('es-ES', this.formattedDateOptions);
    else return '';
  }

  handleStartTime(): string {
    if (!this.summaryData.locationInfo?.formattedEndTime) return '';
    if (
      !this.summaryData.serviceConfiguration?.appointmentTime &&
      this.summaryData.locationInfo?.formattedStartTime
    )
      return (
        this.summaryData.locationInfo?.formattedStartTime +
        '-' +
        this.summaryData.locationInfo?.formattedEndTime
      );
    else
      return this.subtractOneHour(this.summaryData.serviceConfiguration?.appointmentHour as string);
  }

  subtractOneHour(timeStr: string): string {
    if (!timeStr) return '';
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setHours(date.getHours() - 1);
    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${newHours}:${newMinutes}`;
  }

  initializeAutocomplete(): void {
    this.filterAddresses(this.inputFieldElementRef1.nativeElement.value);
    const autocompleteFields: Array<{
      element: ElementRef;
      name: keyof AddressBoxComponent;
    }> = [{ element: this.inputFieldElementRef1, name: 'autocompleteSingleLocation1' }];

    if (autocompleteFields[0].element) {
      if (!this.autocompleted1_ok) {
        const autocomplete = new google.maps.places.Autocomplete(
          autocompleteFields[0].element.nativeElement
        );
        (this[autocompleteFields[0].name] as google.maps.places.Autocomplete | undefined) =
          autocomplete;
        autocomplete.addListener('place_changed', () => {
          this.handlePlaceChanged(autocomplete, autocompleteFields[0].element);
        });
        this.autocompleted1_ok = true;
      }
    } else {
      this.autocompleted1_ok = false;
    }
  }

  private handlePlaceChanged(
    autocomplete: google.maps.places.Autocomplete,
    elementRef: ElementRef
  ): void {
    this.ngZone.run(() => {
      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      //this.hideCenterSelection();

      const address = place.formatted_address as string;
      const location = place.geometry?.location?.toJSON() as LatLng;

      if (this.summaryData.serviceConfiguration?.tyres) {
        this.summaryData.serviceConfiguration.tyres.customAddress = address;
        this.summaryData.serviceConfiguration.tyres.customAddressLatitude = location.lat;
        this.summaryData.serviceConfiguration.tyres.customAddressLongitude = location.lng;
      }
      if (this.summaryData.locationInfo) {
        this.summaryData.locationInfo.destinationAddress = address;
        this.summaryData.locationInfo.destinationAddressLatitude = location.lat;
        this.summaryData.locationInfo.destinationAddressLongitude = location.lng;
      }
      this.deliveryAddressChanged.emit(this.summaryData);
    });
  }

  // hideCenterSelection() {
  //   this.showSelectionBoxSelected = false;
  // }

  showCenterSelection(): void {
    this.showSelectionBoxSelected = true;
  }

  selectUniqueCustomAddress(address: any) {
    this.inputFieldElementRef1.nativeElement.value = address.Address;
    this.showSelectionBoxSelected = false;

    if (this.summaryData.locationInfo) {
      this.summaryData.locationInfo.destinationAddress = address.Address;
      this.summaryData.locationInfo.destinationAddressLatitude = address.AddressLatitude;
      this.summaryData.locationInfo.destinationAddressLongitude = address.AddressLongitude;
    }
    this.deliveryAddressChanged.emit(this.summaryData);
  }

  getAddressesList() {
    this.addressesService.getAddressesList().subscribe((addresses: any) => {
      this.addressesList = addresses.Addresses;
      this.filteredAddressesList = this.addressesList;
    });
  }

  filterAddresses(query: string): void {
    if (!query) {
      this.filteredAddressesList = this.addressesList;
      this.showSelectionBoxSelected = false;
      return;
    }

    this.filteredAddressesList = this.addressesList.filter((address: Address) => {
      if (!address || !address.AddressKey) {
        return false; // Evita elementos nulos o propiedades indefinidas
      }

      return address.AddressKey.toLowerCase().includes(query.toLowerCase());
    });

    this.showSelectionBoxSelected = this.filteredAddressesList.length > 0;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      !target.closest('.autocompleteInput') &&
      !target.closest('.deliveryLocationInput') &&
      !target.closest('.pickupLocationInput')
    ) {
      this.showSelectionBoxSelected = false;
    }
  }
}
