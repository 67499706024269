/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MsalConfigService } from './app/auth/msal-config.service';
import { AppModule } from './app/app.module';

if (document.readyState !== 'loading') {
  initializeApp();
} else {
  document.addEventListener('DOMContentLoaded', initializeApp);
}

async function initializeApp() {
  try {
    await MsalConfigService.createMsalInstance().initialize();
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  } catch (error) {
    console.error('MSAL initialization failed:', error);
  }
}
