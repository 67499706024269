<div class="extra-info" *ngIf="shouldShowCancellationPolicy()">
  <span *ngIf="!isMediumLongDistanceTransfer()">{{ 'cancellationPolicy' | translate }}</span>
  <span *ngIf="isMediumLongDistanceTransfer()">{{
    'medium-long-transfer-cancelation-policy' | translate
  }}</span>
  <button
    class="extra-info-button"
    data-bs-toggle="modal"
    data-bs-target="#politicaDeCancelacionModal"
  >
    {{ 'cancellationPolicyLink' | translate }}</button
  >.
</div>
