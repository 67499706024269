import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { from, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActiveRoutesService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  baseUrl = environment.tracking_url;

  getAuthHeaders(): Observable<{ Authorization: string }> {
    return from(this.authService.getAccessToken()).pipe(
      map((token) => ({ Authorization: 'Bearer ' + token })),
      catchError((error) => {
        console.error('Error fetching token', error);
        throw error;
      })
    );
  }

  activeRoutesMap(page: number, licensePlate?: string): Observable<any> {
    let url = `${this.baseUrl}/fleet-manager/active-routes/map?page=${page}`;
    if (licensePlate) {
      url += `&licensePlate=${licensePlate}`;
    }
    return this.getAuthHeaders().pipe(switchMap((headers) => this.http.get(url, { headers })));
  }

  activeRoutesList(page: number, licensePlate?: string): Observable<any> {
    let url = `${this.baseUrl}/fleet-manager/active-routes/list?page=${page}`;
    if (licensePlate) {
      url += `&licensePlate=${licensePlate}`;
    }
    return this.getAuthHeaders().pipe(switchMap((headers) => this.http.get(url, { headers })));
  }

  activeRoutesDetails(orderHash: string): Observable<any> {
    const url = `${this.baseUrl}/fleet-manager/services/${orderHash}/details`;
    return this.getAuthHeaders().pipe(switchMap((headers) => this.http.get(url, { headers })));
  }

  getChecklistResult(orderHash: string): Observable<any> {
    const savedLanguage = localStorage.getItem('language') || 'es';

    let checklistLanguage;
    switch (savedLanguage) {
      case 'es':
        checklistLanguage = 'es-ES';
        break;
      case 'en':
        checklistLanguage = 'en-GB';
        break;
      case 'fr':
        checklistLanguage = 'fr-Fr';
        break;
      default:
        checklistLanguage = 'es-ES';
    }

    const url = `${this.baseUrl}/tracking/checklist?lang=${checklistLanguage}&caflerHash=${orderHash}`;
    return this.getAuthHeaders().pipe(switchMap((headers) => this.http.get(url, { headers })));
  }
}
