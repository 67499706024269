<section class="container">
  <h3 *ngIf="sectionName">
    <button *ngIf="backButton" (click)="backButtonAction()">
      <img src="../../../assets/icons/flecha-atras.svg" alt="Back button" /></button
    >{{ sectionName | translate }}
  </h3>
  <h3 *ngIf="!sectionName">
    <span class="title-text">{{ titulo }}</span>
    <span class="user-text">{{ usuario }}</span>
  </h3>
  <hr class="mb-0" [ngClass]="{ 'transparent-hr': isHrTransparent }" />
</section>
