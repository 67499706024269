import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InfoModalComponent } from 'src/app/shared/info-modal/info-modal.component';

@Component({
    selector: 'app-modal-tyre-full-info',
    templateUrl: './modal-tyre-full-info.component.html',
    styleUrls: ['./modal-tyre-full-info.component.scss'],
})
export class ModalTyreFullInfoComponent {
    @Input() productKey!: string;
    headerText: string | undefined;
    combinedIncludingDescriptions = '';
    tyre: any;

    constructor(
        private dialogRef: MatDialogRef<InfoModalComponent>,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: { tyre: any }
    ) {
        this.tyre = data.tyre;
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
