import { Component, Input } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { PersonalizationId } from 'src/app/pages/new-service/components/new-service-personalization/new-service-personalization.component';

@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss'],
})
export class CancellationPolicyComponent {
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() currentStep = 0;

  personalizationId = PersonalizationId;

  shouldShowCancellationPolicy(): boolean {
    return (
      this.currentStep > 3 &&
      (this.summaryData.productInformation.product?.ProductKey !==
        this.personalizationId.MotHomologation ||
        this.currentStep > 5)
    );
  }

  isMediumLongDistanceTransfer(): boolean {
    return (
      this.summaryData.productInformation.product?.ProductKey ===
      this.personalizationId.MediumLongDistanceTranfer
    );
  }
}
