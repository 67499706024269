import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AddressesResponse } from '../../new-service/interfaces/Address';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  private apiUrl = environment.identity_url;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getAddresses(page: number): Observable<any> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.get(`${this.apiUrl}/user/me/address?page=${page}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getAddressesList(): Observable<any> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.get(`${this.apiUrl}/user/me/address/favorite`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getAllAddressesList(): Observable<AddressesResponse> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.get<AddressesResponse>(`${this.apiUrl}/user/me/address`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  postAddress(addressData: any): Observable<any> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.post(`${this.apiUrl}/user/me/address`, addressData, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  patchAddress(addressId: string, addressData: any): Observable<any> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.patch(`${this.apiUrl}/user/me/address/${addressId}`, addressData, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getAddress(addressId: string): Observable<any> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.get(`${this.apiUrl}/user/me/address/${addressId}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  deleteAddress(addressId: string): Observable<any> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (!token) {
          throw new Error('No token available');
        }
        return this.http.delete(`${this.apiUrl}/user/me/address/${addressId}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }
}
