<div
  class="sidebar"
  [ngClass]="{ 'sidebar-collapsed': isCollapsed, 'sidebar-expanded': !isCollapsed }"
>

<button 
  class="expand-nav" 
  (click)="toggleSidebar(); resetHoverState()">
  <img
    [src]="isCollapsed ? 'assets/new-expand-icon.svg' : 'assets/new-minimize-icon.svg'"
    alt="Toggle sidebar"
    class="expand-nav-img"
    (mouseover)="showNavigation()"
    (mouseout)="hideNavigation()"
  />
  <div
    *ngIf="isCollapsed"
    [class.visible]="isHovered && !isClickBlocked"
    class="show-navigation"
  >
    <img
      class="nav-placeholder"
      src="../../assets/icons/navbar/nav-placeholder.svg"
    />
    <div class="show-navigation-text">Show navigation</div>
  </div>
  <div
    *ngIf="!isCollapsed"
    [class.visible]="isHovered && !isClickBlocked"
    class="hide-navigation"
  >
    <img
      class="nav-placeholder"
      src="../../assets/icons/navbar/nav-placeholder.svg"
    />
    <div class="hide-navigation-text">Hide navigation</div>
  </div>
</button>


  <div class="upper-half">
    <ul class="nav flex-column section">
      <ng-container *ngFor="let section of sections">
        <li *ngIf="section.children && section.children.length > 0" class="nav-section">
          <div [ngClass]="{ 'd-none': isCollapsed || !section.title, 'section-title': true }">
            {{ section.title | translate }}
          </div>
          <hr [ngClass]="{ 'd-none': !isCollapsed || !section.title }" />
          <br [ngClass]="{ 'd-none': section.title }" />
          <ul>
            <li
              *ngFor="let subSection of section.children"
              class="children"
              [ngClass]="{
                active: checkSamePath(subSection.path) || checkSamePath(subSection.title),
              }"
            >
              <a
                [routerLink]="subSection.path"
                (click)="subSection.path === 'new-service' && clearSummaryData()"
              >
                <img
                  class="subSection-icon"
                  [src]="subSection.icon"
                  [alt]="subSection.title | translate"
                />
                <span [ngClass]="{ 'd-none': isCollapsed }">{{
                  subSection.title | translate
                }}</span>
                <div class="nav-placeholder-container">
                  <img class="nav-placeholder" src="../../assets/icons/navbar/nav-placeholder.svg" />
                  <div class="text-overlay">{{ subSection.title | translate }}</div>
                </div>
                <span
                  [ngClass]="{ 'd-none': isCollapsed }"
                  class="isNewBadge"
                  *ngIf="subSection.isNew"
                  >{{ 'newBadge' | translate }}</span
                >
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="!section.children || section.children.length === 0" class="nav-item">
          <a [routerLink]="section.path">
            <img [src]="section.icon" [alt]="section.title | translate" />
            <span [ngClass]="{ 'd-none': isCollapsed }">{{ section.title | translate }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

