<div *ngIf="loading" class="cssload-container">
  <div class="cssload-speeding-wheel">
    <img
      src="../../../../assets/gifs/logo-loading.gif"
      alt="Loading..."
      height="60.19px"
      width="107px"
    />
  </div>
</div>
