<div
  *ngIf="loading"
  class="w-100 d-flex justify-content-center align-items-center"
  style="height: 500px"
>
  <div>
    <img
      src="../../../assets/gifs/logo-loading.gif"
      alt="Loading..."
      height="60.19px"
      width="107px"
    />
  </div>
</div>
