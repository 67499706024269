import { Component, Input } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent {
  @Input() summaryData: SummaryData = {} as SummaryData;

  constructor(private translate: TranslateService) {}

  isSameContact(): boolean {
    const origin = this.summaryData.locationInfo;
    const destination = this.summaryData.locationInfo;
    return (
      origin?.originContactName === destination?.destinationContactName &&
      origin?.originContactLastName === destination?.destinationContactLastName &&
      origin?.originContactPhoneNumber === destination?.destinationContactPhoneNumber
    );
  }

  handleIsExternal(value?: boolean): string {
    return value
      ? this.translate.instant('internal_contact')
      : this.translate.instant('external_contact');
  }
}
