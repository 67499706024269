import { Component, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Tags, User } from 'src/app/pages/new-service/interfaces/SummaryData';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { TagManagerService } from './services/tag-manager.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tag-manager',
  templateUrl: './tag-manager.component.html',
  styleUrls: ['./tag-manager.component.scss']
})
export class TagManagerComponent {

  @Output() selectedTagsChange = new EventEmitter<Tags[]>();
  @Input() showDescription = true;

  tags: string[] = [];
  tagNames: Tags[] = [];
  //selectedTags: string[] = [];
  selectedTags: Tags[] = [];
  placeholder: string = this.translateService.instant('click_here_search_or_create_tag');;
  firstInput?: boolean;
  tagColors: Map<string, string> = new Map();
  isDropdownVisible = false;
  colors = ['#d8eafe', '#a1e6a1', '#f4e6a1', '#f1a1a1', '#bda1f1', '#ffa1d8'];
  inputValue: string = '';
  showSuggestions: boolean = false;
  filteredTags: string[] = [];
  inputColor: string = '';
  isEditPopupVisible: boolean = false;
  editedTagIndex: number = -1;
  editedTagName: string = '';
  originalTagName: string = '';
  popupPosition = { x: 0, y: 0 };
  isDuplicateTag: boolean = false;
  isDeleteModalVisible: boolean = false;
  tagToDelete: string = '';
  hidePlaceholder: boolean = false;

  onInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value.trim();
    this.inputValue = input;

    if (!this.inputColor && input) {
      this.inputColor = this.getTagColor(input);
    }

    if (input) {
      this.filteredTags = this.tagNames.filter(tag => tag.Tag?.toLowerCase().startsWith(input.toLowerCase())).map(tag => tag.Tag!);
    } else {
      this.filteredTags = [];
      this.inputColor = '';
    }
  }

  createTag(tag: string): void {
    if (tag.trim()) {

      const trimmedTag = tag.trim();

      if (this.tagNames.some(tag => tag.Tag === trimmedTag) || this.selectedTags.some(tag => tag.Tag === trimmedTag)) {
        alert('El tag ya existe o está seleccionado. Por favor, elige otro nombre.');
        return;
      }

      this.tagManagerService.createTag({ Tag: trimmedTag }).subscribe(
        (response) => {
          console.log('Nombre del tag:', trimmedTag);
          this.tagManagerService.getTagNames().subscribe((user: User) => {
            const createdTag = user.Tags?.find(t => t.Tag === trimmedTag);
            if (createdTag) {
              this.selectedTags.push({ Id: createdTag.Id, Tag: createdTag.Tag });
              this.selectedTagsChange.emit(this.selectedTags);
              this.tagNames = this.tagNames.filter(tag => tag.Tag !== createdTag.Tag);
              this.loadtagNames();
            }
          });
        },
        (error) => {
          console.error('Error creating tag:', error);
        }
      );

      if (this.inputColor) {
        this.tagColors.set(trimmedTag, this.inputColor);
      } else {
        this.getTagColor(trimmedTag);
      }

      this.inputValue = '';
      this.inputColor = '';
      this.filteredTags = [];
      this.hidePlaceholder = true;
      this.showSuggestions = false;
      this.isDropdownVisible = false;
      this.firstInput = true;
    }
  }


  showSuggestionsList() {
    this.showSuggestions = true;
    this.isDropdownVisible = false;
    this.filteredTags = [];
    this.inputValue = '';
  }
  constructor(private tagManagerService: TagManagerService, private translateService: TranslateService) {
    this.tagNames.forEach((tag) => {
      if (tag.Tag) {
        this.getTagColor(tag.Tag);
      }
    });
    this.tagNames.forEach((tag, index) => {
      if (tag.Tag) {
        this.tagColors.set(tag.Tag, this.getRandomColor(index));
      }
      console.log('Tag names:', this.tagNames);
    });
  }

  onFocus(): void {
    this.placeholder = this.translateService.instant('search_or_create_tag');
  }

  onBlur(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value) {
      this.placeholder = this.translateService.instant('click_here_search_or_create_tag');
      this.isDropdownVisible = false;
      this.firstInput = true;
    }
  }

  ngOnInit() {
    this.loadtagNames();
    this.firstInput = true;
    console.log('Tag names:', this.tagNames);
  }

  changeInput() {
    this.firstInput = !this.firstInput;
    if (this.tagNames) {
      this.isDropdownVisible = true;
    }
    this.showSuggestions = false;
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
    this.showSuggestions = false;
  }

  getRandomColor(index: number): string {
    this.colors = ['#d8eafe', '#a1e6a1', '#f4e6a1', '#f1a1a1', '#bda1f1', '#ffa1d8'];
    return this.colors[index % this.colors.length];
  }

  darkenColor(color: string, factor: number): string {
    const r = Math.floor(parseInt(color.slice(1, 3), 16) * factor);
    const g = Math.floor(parseInt(color.slice(3, 5), 16) * factor);
    const b = Math.floor(parseInt(color.slice(5, 7), 16) * factor);
    return `rgb(${r}, ${g}, ${b})`;
  }

  selectTag(tag: string) {
    const selectedTag = this.tagNames.find(t => t.Tag === tag);
    if (selectedTag && !this.selectedTags.some(t => t.Tag === tag)) {
      this.selectedTags.push({ Id: selectedTag.Id, Tag: tag });
      this.tagNames = this.tagNames.filter(t => t.Tag !== tag);
      this.selectedTagsChange.emit(this.selectedTags);
    }
    this.firstInput = true;
  }

  removeTag(tag: string, event: MouseEvent) {
    event.stopPropagation();
    this.selectedTags = this.selectedTags.filter(t => t.Tag !== tag);
    this.tagNames.push({ Id: '', Tag: tag });
  }

  getTagColor(tag: string): string {
    if (!this.tagColors.has(tag)) {
      const index = this.getIndexFromTag(tag);
      const color = this.colors[index % this.colors.length];
      this.tagColors.set(tag, color);
    }
    return this.tagColors.get(tag)!;
  }

  private getIndexFromTag(tag: string): number {
    return tag ? tag.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) : 0;
  }

  openEditPopup(tag: string, index: number, event: MouseEvent): void {
    event.stopPropagation();
    this.isEditPopupVisible = true;
    this.editedTagIndex = index;
    this.editedTagName = tag;
    this.originalTagName = tag;
    this.isDuplicateTag = false;
    const targetElement = event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();
    this.popupPosition = {
      x: rect.right + 10,
      y: rect.top,
    };
  }

  saveTag(): void {
    if (this.editedTagName.trim()) {
      const newTagName = this.editedTagName.trim();
      const oldTag = this.tagNames[this.editedTagIndex];

      if (this.tagNames.some(tag => tag.Tag === newTagName) && oldTag.Tag !== newTagName) {
        this.isDuplicateTag = true;
        return;
      }

      this.isDuplicateTag = false;
      const selectedIndex = oldTag ? this.selectedTags.findIndex(tag => tag.Tag === oldTag.Tag) : -1;
      if (selectedIndex > -1) {
        this.selectedTags[selectedIndex] = { Tag: newTagName };
      }

      const tagId = oldTag.Id;
      console.log('id:', tagId);
      if (newTagName && !this.tagNames.some(tag => tag.Tag === newTagName)) {
        console.log('newTagName:', newTagName);
        if (tagId) {
          this.tagManagerService.updateTag(tagId, newTagName).subscribe({
            next: () => {
              oldTag.Tag = newTagName;
              this.loadtagNames();
              console.log('Tag updated successfully');
            },
            error: (error) => {
              console.error('Error updating tag:', error);
            }
          });
        }
      }

      if (oldTag.Tag !== newTagName) {
        const oldTagIndex = this.tagNames.findIndex(tag => tag === oldTag);
        if (oldTagIndex > -1) {
          this.tagNames.splice(oldTagIndex, 1);
        }
      }

      this.closePopup();
    }
  }

  deleteTag(): void {
    this.isDeleteModalVisible = true;
  }

  closePopup(): void {
    this.isEditPopupVisible = false;
    this.editedTagIndex = -1;
    this.editedTagName = '';
  }

  handleEnter(event: any): void {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
    this.saveTag();
  }

  confirmDelete(): void {
    const tagToDelete = this.tagNames[this.editedTagIndex]?.Tag;
    if (!tagToDelete) return;

    const selectedIndex = this.selectedTags.findIndex(tag => tag.Tag === tagToDelete);
    if (selectedIndex > -1) {
      this.selectedTags.splice(selectedIndex, 1);
    }

    const availableIndex = this.tagNames.findIndex(tag => tag.Tag === tagToDelete);
    if (availableIndex > -1) {
      const tagId = this.tagNames[availableIndex].Id;
      console.log('Tag ID:', tagId);
      if (tagId) {
        this.tagManagerService.deleteTag(tagId).subscribe({
          next: () => {
            this.tagNames.splice(availableIndex, 1);
            console.log('Tag deleted successfully');
          },
          error: (error) => {
            console.error('Error deleting tag:', error);
          }
        });
      }
      this.loadtagNames();
    }

    this.closeDeleteModal();
    this.closePopupModal();
    this.closePopup();
  }

  closeDeleteModal(): void {
    this.isDeleteModalVisible = false;
  }

  closePopupModal(): void {
    this.editedTagIndex = -1;
    this.isDeleteModalVisible = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const popupElement = document.querySelector('.edit-popup');
    if (popupElement && !popupElement.contains(event.target as Node)) {
      this.closeEditPopup();
    }
  }

  closeEditPopup(): void {
    this.isEditPopupVisible = false;
    this.editedTagName = '';
    this.editedTagIndex = -1;
  }

  loadtagNames() {
    this.tagManagerService.getTagNames().subscribe((user: User) => {
      this.tagNames = user.Tags?.map((tag: Tags) => ({
        Id: tag.Id ?? '',
        Tag: tag.Tag ?? '',
      })) ?? [];
    });
  }

}
