import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatatableService } from 'src/app/shared/datatable/services/datatable.service';
import { Status } from 'src/app/shared/status/enum/Status.enum';

@Component({
  selector: 'app-modal-cancel-vehicle-storage',
  templateUrl: './modal-cancel-vehicle-storage.component.html',
  styleUrls: ['./modal-cancel-vehicle-storage.component.scss'],
})
export class ModalCancelVehicleStorageComponent implements OnInit {
  constructor(
    private router: Router,
    private dataService: DatatableService,
    private dialogRef: MatDialogRef<ModalCancelVehicleStorageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderHash: string; status: number; }
  ) { }

  headerText: string | undefined;
  routesConfig = this.router.config;
  orderHash!: string;
  status!: number;

  async ngOnInit() {
    this.headerText = this.routesConfig.find(
      (e) => e.path === this.router.url.split('/')[1]
    )?.data?.['title'];
  }

  cancelService(): void {
    this.dataService.cancelService(this.data.orderHash, Status.Pending).subscribe(
      (result) => {
        if (result) {
          this.dialogRef.close(result);
        }
      },
      (error) => {
        console.error('Error al cancelar el servicio', error);
        this.dialogRef.close(false);
      }
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
