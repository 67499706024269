import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatatableService } from '../../services/datatable.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-address-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModalComponent implements OnInit {
  constructor(
    private router: Router,
    private dataService: DatatableService,
    private dialogRef: MatDialogRef<CancelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderHash: string; status: number }
  ) {}

  headerText: string | undefined;
  routesConfig = this.router.config;
  orderHash!: string;
  status!: number;

  async ngOnInit() {
    this.headerText = this.routesConfig.find(
      (e) => e.path === this.router.url.split('/')[1]
    )?.data?.['title'];
  }

  cancelService(): void {
    this.dataService.cancelService(this.data.orderHash, this.data.status).subscribe(
      (result) => {
        if (result) {
          this.dialogRef.close(result);
        }
      },
      (error) => {
        console.error('Error al cancelar el servicio', error);
        this.dialogRef.close(false);
      }
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
