import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Extra } from 'src/app/shared/interfaces/extra.interface';

@Injectable({
  providedIn: 'root',
})
export class ExtrasService {
  private extrasChangedSource = new Subject<Extra[]>();
  extrasChanged$ = this.extrasChangedSource.asObservable();

  emitExtrasChanged(extras: Extra[]) {
    this.extrasChangedSource.next(extras);
  }
}
