<div class="header">
  <img
    (click)="onClose()"
    (keyup)="onClose()"
    tabindex="0"
    src="/assets/icons/close-modal.svg"
    alt="closeIcon"
    class="pointer"
  />
</div>
<section #section>
  <app-alert
    #alertComponent
    [text]="errorAlertText"
    [type]="2"
    [isForDialog]="true"
    *ngIf="showErrorAlert"
    (closeAlertEvent)="onAlertClosed()"
  ></app-alert>

  <div class="title-box">
    <h3>{{ 'replacementVehicle' | translate }}</h3>
    <p>{{ 'refuelingDescription' | translate }}</p>
  </div>
  <div class="vehicle-box">
    <h4>{{ 'your_vehicle' | translate }}</h4>
    <div class="vehicle-row">
      <div class="vehicle-data">
        <p>
          Peugeot 208 <small>{{ 'or_similar' | translate }}</small>
        </p>
        <div class="vehicle-description">
          <div>
            <div>
              <img
                src="../../../../../../../assets/icons/extras-modal/sustitution-vehicle/gears.svg"
                alt=""
              />
              <span>{{ 'manual_transmission' | translate }}</span>
            </div>
            <div>
              <img
                src="../../../../../../../assets/icons/extras-modal/sustitution-vehicle/fuel-type.svg"
                alt=""
              />
              <span>{{ 'petrol' | translate }}</span>
            </div>
          </div>
          <div>
            <div>
              <img
                src="../../../../../../../assets/icons/extras-modal/sustitution-vehicle/people.svg"
                alt=""
              />
              <span>{{ 'four_places' | translate }}</span>
            </div>
            <div>
              <img
                src="../../../../../../../assets/icons/extras-modal/sustitution-vehicle/doors.svg"
                alt=""
              />
              <span>{{ 'three_gates' | translate }}</span>
            </div>
            <div>
              <img
                src="../../../../../../../assets/icons/extras-modal/sustitution-vehicle/bag.svg"
                alt=""
              />
              <span>{{ 'one_bag' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <img src="../../../../../../../assets/images/peugeot-208.png" alt="" class="vehicle-image" />
    </div>
  </div>
  <form [formGroup]="sustitutionForm">
    <div class="pickup-box">
      <h4>{{ 'which_day_of_return' | translate }}</h4>
      <div class="d-flex flex-column w-100">
        <label for="station">{{ 'pickup_day' | translate }}</label>
        <select
          id="station"
          formControlName="returnDate"
          class="form-control"
          [ngClass]="{
            'error-input': invalidForm && !sustitutionForm.get('returnDate')?.valid,
          }"
        >
          <option value="" disabled selected>{{ 'choose_days' | translate }}</option>
          <option *ngFor="let option of options" [ngValue]="option" [disabled]="option.Disabled">
            {{ 'return' | translate }} {{ option.Date }} - {{ option.Price }}
          </option>
        </select>
        <div *ngIf="sustitutionForm.get('returnDate')?.invalid && invalidForm" class="error">
          {{ getErrorMessage('returnDate') }}
        </div>
      </div>
      <div class="disclaimer">
        <img src="/assets/icons/information-circle.svg" alt="" />
        <p>
          {{ 'sustitution_topping_driver_disclaimer' | translate }}
        </p>
      </div>
    </div>
    <div class="contact-box">
      <div class="title">
        <h4>{{ 'driver_contact' | translate }}</h4>
        <p>
          {{ 'sustitution_topping_contact_driver' | translate }}
        </p>
      </div>
      <div class="contact-form">
        <div class="input-form">
          <label for="contactName">{{ 'name' | translate }}</label>
          <input
            type="text"
            formControlName="contactName"
            [ngClass]="{
              'error-input': invalidForm && !sustitutionForm.get('contactName')?.valid,
            }"
          />
          <div *ngIf="sustitutionForm.get('contactName')?.invalid && invalidForm" class="error">
            {{ getErrorMessage('contactName') }}
          </div>
        </div>
        <div class="input-form">
          <label for="contactLastName">{{ 'last_name' | translate }}</label>
          <input
            type="text"
            formControlName="contactLastName"
            [ngClass]="{
              'error-input': invalidForm && !sustitutionForm.get('contactLastName')?.valid,
            }"
          />
          <div *ngIf="sustitutionForm.get('contactLastName')?.invalid && invalidForm" class="error">
            {{ getErrorMessage('contactLastName') }}
          </div>
        </div>
        <div class="input-form">
          <label for="contactPhone">{{ 'mobile_number' | translate }}</label>
          <div class="select-group">
            <select class="prefix-select" name="prefix" formControlName="phoneRegion">
              <option value="esp">+34</option>
              <option value="gbr">+44</option>
              <option value="fra">+33</option>
            </select>
            <input
              type="tel"
              formControlName="contactPhone"
              class="form-control phone-input white-group-box"
              [ngClass]="{
                'error-input': invalidForm && !sustitutionForm.get('contactPhone')?.valid,
              }"
            />
          </div>
          <div *ngIf="sustitutionForm.get('contactPhone')?.invalid && invalidForm" class="error">
            {{ getErrorMessage('contactPhone') }}
          </div>
        </div>
        <div class="input-form">
          <label for="contactEmail">{{ 'email_address' | translate }}</label>
          <input
            type="email"
            formControlName="contactEmail"
            [ngClass]="{
              'error-input': invalidForm && !sustitutionForm.get('contactEmail')?.valid,
            }"
          />
          <div *ngIf="sustitutionForm.get('contactEmail')?.invalid && invalidForm" class="error">
            {{ getErrorMessage('contactEmail') }}
          </div>
          <div class="disclaimer">
            <img
              src="../../../../../../../assets/icons/extras-modal/sustitution-vehicle/shield-exclamation.svg"
              alt=""
            />
            <p>
              {{ 'sustitution_topping_contact_driver_for_documentation' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button (click)="addTopping()">{{ 'add' | translate }}</button>
    </div>
  </form>
</section>
