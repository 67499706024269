import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Company, UserProfile } from '../interfaces/company-settings.interface';
import { AuthService } from 'src/app/auth/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyAccountService {
  private apiUrl = environment.identity_url;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  private getAuthHeaders(): Observable<HttpHeaders> {
    return from(this.authService.getAccessToken()).pipe(
      map((token) => {
        if (!token) {
          throw new Error('Failed to acquire token');
        }
        return new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
      })
    );
  }

  getCompanyData(): Observable<Company> {
    return this.getAuthHeaders().pipe(
      switchMap((headers) => this.http.get<Company>(`${this.apiUrl}/user/me/business`, { headers }))
    );
  }

  getUserDetail(): Observable<UserProfile> {
    return this.getAuthHeaders().pipe(
      switchMap((headers) => this.http.get<UserProfile>(`${this.apiUrl}/user/me`, { headers }))
    );
  }

  updateCompanyData(data: any): Observable<any> {
    return this.getAuthHeaders().pipe(
      switchMap((headers) =>
        this.http.put<any>(`${this.apiUrl}/user/me/business`, data, { headers })
      )
    );
  }

  updateUserData(data: any): Observable<any> {
    return this.getAuthHeaders().pipe(
      switchMap((headers) => this.http.put<any>(`${this.apiUrl}/user/me`, data, { headers }))
    );
  }
}
