<body>
  <div class="tag-container w-100">
    <div class="tag-title">
      <div>
        <img src="../../../../../assets/icons/tag-icon.svg" />
        {{ 'tag_your_service' | translate }}
        <span>{{ 'optional' | translate }}</span>
      </div>
    </div>
    <div class="tag-description" *ngIf="showDescription">
      {{ 'tag_your_service_description' | translate }}
    </div>
    <div class="tag-label">{{ 'add_tag' | translate }}</div>
    <div class="tag-input" *ngIf="firstInput" (click)="changeInput()">
      <div class="chip" *ngFor="let tag of selectedTags" [ngStyle]="{
                'background-color': tag.Tag ? getTagColor(tag.Tag) : 'defaultColor', 
                'color': tag.Tag ? darkenColor(getTagColor(tag.Tag), 0.6) : 'defaultColor'
                }">
        {{ tag.Tag }}
        <img src="../../../../../assets/icons/remove-icon.svg" class="close"
          (click)="tag.Tag && removeTag(tag.Tag, $event)" />
      </div>

      <div class="tag-input-text" *ngIf="selectedTags.length === 0">{{ 'create_or_choose_tag' | translate }}</div>
      <img src="../../../assets/icons/chevron-down.svg" class="chevron" />
    </div>
    <div class="input-container" *ngIf="!firstInput">
      <div class="input-header">
        <input class="input" type="text" [placeholder]="placeholder" (input)="onInput($event)" (focus)="onFocus()"
          (blur)="onBlur($event)" (click)="showSuggestionsList()">
        <img src="../../../assets/icons/chevron-down.svg" class="chevron-2" (click)="changeInput()" />
      </div>
      <div class="input-body" (click)="toggleDropdown()">
        <input type="text" class="input" placeholder="{{ 'select_an_existing_one' | translate }}" readonly>
        <div class="tag-dropdown" *ngIf="showSuggestions && filteredTags.length > 0">
          <div class="tag-dropdown-item" *ngFor="let tag of filteredTags" (click)="selectTag(tag)">
            <span class="tag" [style.color]="darkenColor(getTagColor(tag), 0.6)"
              [style.backgroundColor]="getTagColor(tag)">
              {{ tag }}
            </span>
          </div>
        </div>
        <div class="create-new-tag" *ngIf="showSuggestions && !filteredTags.length">
          <span class="create-text">{{ 'create' | translate }}</span>
          <span class="new-tag" [style.color]="darkenColor(getTagColor(inputColor), 0.6)"
            [style.backgroundColor]="darkenColor(getTagColor(inputColor), 1)">
            {{ inputValue }}
          </span>
          <button class="create-btn" (click)="createTag(inputValue)">
            <span class="create-text-btn">{{ 'create' | translate }}</span>
          </button>
        </div>
        <ng-container *ngIf="isDropdownVisible">
          <div class="tag-dropdown">
            <div class="tag-dropdown-item" *ngFor="let tag of tagNames; let i = index"
              (click)="tag.Tag && selectTag(tag.Tag)">
              <span class="tag" [style.color]="tag.Tag ? darkenColor(getTagColor(tag.Tag), 0.6) : 'defaultColor'"
                [style.backgroundColor]="tag.Tag ? darkenColor(getTagColor(tag.Tag), 1) : 'defaultColor'">
                {{ tag.Tag }}
              </span>
              <img src="../../../assets/icons/pencil-icon.svg" class="edit-icon"
                (click)="tag.Tag && openEditPopup(tag.Tag, i, $event)" />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="overlay" *ngIf="isEditPopupVisible" (click)="closePopup()">
    </div>

    <div class="edit-popup" *ngIf="isEditPopupVisible" [style.top.px]="popupPosition.y"
      [style.left.px]="popupPosition.x" (click)="$event.stopPropagation()">
      <div class="popup-header">
        <div class="popup-title">{{ 'edit_your_tag' | translate }}</div>
        <input class="popup-input" type="text" [(ngModel)]="editedTagName" (keydown.enter)="handleEnter($event)" />
        <div *ngIf="isDuplicateTag" class="error-message">
          {{ 'tag_name_already_exists' | translate }}
        </div>
        <div class="confirm-button" *ngIf="editedTagName !== originalTagName" (click)="saveTag()">
          <div class="button-text">{{ 'confirm_tag' | translate }}</div>
        </div>
      </div>
      <div class="popup-footer">
        <button class="delete-btn" (click)="deleteTag()">
          <img src="../../../assets/icons/trash-icon.svg" />
          Eliminar
        </button>
      </div>
    </div>
  </div>

  <div class="modal-overlay" *ngIf="isDeleteModalVisible">
    <div class="modal">
      <button class="close-btn" (click)="closeDeleteModal()">
        <img src="../../../assets/icons/x.svg" alt="">
      </button>
      <h2 class="modal-title">{{ 'confirm_delete_tag' | translate }}</h2>
      <p class="modal-description">
        {{ 'before_deleting_tag_warning' | translate }}
      </p>
      <div class="modal-actions">
        <button class="btn cancel-btn" (click)="closeDeleteModal()">{{ 'cancel' | translate }}</button>
        <button class="btn confirm-btn" (click)="confirmDelete()">{{ 'continue' | translate }}</button>
      </div>
    </div>
  </div>




</body>