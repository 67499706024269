import { Component, Input, OnInit } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { Extra } from 'src/app/shared/interfaces/extra.interface';
import { TranslateService } from '@ngx-translate/core';
import { PersonalizationId } from 'src/app/pages/new-service/components/new-service-personalization/new-service-personalization.component';
import { NAME_DICTIONARY } from 'src/app/shared/constants/constants';
import { GetValuesService } from 'src/app/core/services/get-values.service';

@Component({
  selector: 'app-pricing-summary',
  templateUrl: './pricing-summary.component.html',
  styleUrls: ['./pricing-summary.component.scss'],
})
export class PricingSummaryComponent implements OnInit {
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() currentStep = 0;
  @Input() extras: Extra[] = [];
  @Input() promotionCode = '';
  @Input() promotionCodeValidator = false;
  @Input() promotionCodeValue = 0;
  @Input() isFunnelDialog = false;

  personalizationId = PersonalizationId;
  nameDictionary = NAME_DICTIONARY;
  serviceInfo: { [key: string]: { name: string; icon: string; price?: number } } = {};
  licensePlateCount = 0;
  showLicensePlateCount = false;

  private iconDictionary: { [key: string]: string } = {
    insurance: 'assets/icons/insurance-during-service.svg',
    refill: 'assets/icons/refuel.svg',
    sustitutionVehicle: 'assets/icons/sustitution-vehicle.svg',
    wash: 'assets/icons/lavados-icon.svg',
    checkStatusVehicle: 'assets/icons/check-status.svg',
    tyres: 'assets/icons/tyres.svg',
    accesory: 'assets/icons/cafler-fresh.svg',
    'insurance-car-topping': 'assets/icons/insurance-during-service.svg',
    'insurance-minivan-topping': 'assets/icons/insurance-during-service.svg',
    'insurance-motorbike-topping': 'assets/icons/insurance-during-service.svg',
    'tyre-inflation': 'assets/icons/tyres.svg',
  };

  constructor(
    private translate: TranslateService,
    private getValuesService: GetValuesService
  ) { }

  ngOnInit(): void {
    if (
      this.summaryData?.vehicleInformation?.licensePlate &&
      this.summaryData.vehicleInformation.licensePlate.length > 1
    ) {
      this.showLicensePlateCount = true;
    }

    //console.log('pricing: ', this.summaryData.productInformation.product?.Price); // this.generateServiceInfo();
  }

  shouldShowPricingSummary(): boolean {
    return this.currentStep > 3;
  }

  // generateServiceInfo(): void {
  //     const availableToppings = this.summaryData.availableToppings ?? [];
  //     for (const toppingCategory of availableToppings) {
  //         for (const product of toppingCategory.Products) {
  //             const productKey = product.ProductKey;
  //             const name = this.nameDictionary[productKey] || productKey;
  //             const icon = this.getIconForProductKey(productKey);
  //             this.serviceInfo[product.Id] = {
  //                 name,
  //                 icon,
  //                 price: product.Price,
  //             };
  //         }
  //     }
  //     for (const extra of this.extras) {
  //         const productKey = extra.productKey;
  //         const name = this.nameDictionary[productKey] || productKey;
  //         const icon = this.getIconForProductKey(productKey);
  //         this.serviceInfo[extra.productId] = {
  //             name,
  //             icon,
  //             price: extra.serviceConfiguration?.MonetaryAmount || extra.price,
  //         };
  //     }
  // }

  getServiceInfo(productId: string): { name: string; icon: string; price?: number } {
    const serviceInfo = this.serviceInfo[productId];

    if (!serviceInfo || serviceInfo.name === 'Unknown Service') {
      const extraWash = this.extras.find((extra) => extra.productKey.includes('wash'));
      if (extraWash) {
        return {
          name: this.translate.instant(this.nameDictionary[extraWash.productKey] || 'Wash Service'),
          icon: this.getIconForProductKey(extraWash.productKey),
          price: extraWash.price,
        };
      }
    }
    if (serviceInfo && serviceInfo.name === 'Unknown Service' && serviceInfo.price === 0) {
      const refuelExtra = this.extras.find((extra) => extra.productKey.includes('refill'));
      if (refuelExtra) {
        const monetaryAmount = refuelExtra.serviceConfiguration.MonetaryAmount;
        const fuelType = this.summaryData.vehicleInformation.fuelType;
        const icon = this.getIconForProductKey(refuelExtra.productKey);

        return {
          name: fuelType as string,
          icon: icon,
          price: monetaryAmount,
        };
      }
    }

    if (serviceInfo && serviceInfo.name === 'Unknown Service' && serviceInfo.price === 0) {
      const insuranceExtra = this.extras.find((extra) => extra.productKey.includes('insurance'));
      if (insuranceExtra) {
        const icon = 'assets/icons/insurance-during-service.svg';

        return {
          name: this.translate.instant('insurance'),
          icon: icon,
          price: insuranceExtra.serviceConfiguration?.MonetaryAmount || insuranceExtra.price,
        };
      }
    }

    return serviceInfo || { name: 'Unknown Service', icon: 'assets/icons/default-icon.svg' };
  }

  private getIconForProductKey(productKey: string): string {
    console.log(productKey);
    if (productKey.includes('wash')) {
      return 'assets/icons/lavados-icon.svg';
    }
    if (productKey.includes('refill') || productKey.includes('refueling')) {
      return 'assets/icons/refuel.svg';
    }
    if (productKey.includes('replacement') || productKey.includes('sustitution')) {
      return 'assets/icons/sustitution-vehicle.svg';
    }
    if (productKey.includes('checklist')) {
      return 'assets/icons/check-status.svg';
    }
    if (productKey.includes('tyre')) {
      return 'assets/icons/tyres.svg';
    }
    return this.iconDictionary[productKey] || 'assets/icons/default-icon.svg';
  }

  isRefueling(productKey: string): boolean {
    return productKey.includes('refill') || productKey.includes('refueling');
  }

  isRefuelingProduct(): boolean {
    return this.summaryData.productInformation.product?.ProductKey?.includes('refueling') ?? false;
  }

  getProductTitle(): string {
    const product = this.summaryData.productInformation?.product;
    if (product?.ProductKey === '1c174844-abdb-403c-b0f1-2443d30485f6') {
      return this.summaryData.vehicleInformation.fuelType as string;
    }
    if (this.isOutOfRange()) {
      return product?.outOfRangeTitle ? this.translate.instant(product?.outOfRangeTitle) : '';
    }
    if (product?.ProductKey === '360-pre-mot') {
      return this.translate.instant('360-pre-mot');
    }
    return product?.title ? this.translate.instant(product?.title) : '';
  }
  getProductPrice(): string {
    const product = this.summaryData.productInformation?.product;
    this.licensePlateCount = this.summaryData.vehicleInformation.licensePlate?.length || 0;

    const totalProductPrice = parseFloat(
      ((product?.Price ?? 0) * this.licensePlateCount).toFixed(1)
    );
    // if (this.summaryData.isInspectionPaid && this.currentStep === 5) {
    //     return '0€';
    // }
    return `${totalProductPrice}€`;
  }

  getExtraName(extra: Extra): string {
    if (this.isRefueling(extra.productKey)) {
      return this.summaryData.vehicleInformation.fuelType as string;
    }
    return this.translate.instant(this.getServiceInfo(extra.productId).name);
  }

  getExtraPrice(extra: Extra): string {
    if (this.isRefueling(extra.productKey) || extra.productKey.includes('insurance')) {
      return `${extra.serviceConfiguration?.MonetaryAmount}€`;
    }
    return `${extra.price}€`;
  }

  getCaflerFeeLabel(): string {
    return this.isRefuelingProduct()
      ? this.translate.instant('refillFee')
      : this.translate.instant('caflerFee');
  }

  getTotalValue(): number {
    const product = this.summaryData.productInformation?.product;
    const locationInfo = this.summaryData.locationInfo;

    let price = product?.Price ?? 0;
    const monetaryAmount = this.summaryData.serviceConfiguration?.monetaryAmount ?? 0;
    const urgencyFee = this.summaryData.urgencyFee ? 4 : 0;
    const caflerFee = product?.CaflerFee ?? 0;
    const addedNonScheduledMotPrice = product?.addedNonScheduledMotPrice ?? 0;

    // if (this.summaryData.IsInspectionPaid && product?.ProductKey === this.personalizationId.Itv) {
    //   price = 0;
    // }

    let specificHoursPrice = 0;
    if (locationInfo?.isSpecificHours && locationInfo?.fee) {
      specificHoursPrice = locationInfo.fee;
    }

    const toppingsTotal = this.extras.reduce((total, extra) => {
      return total + (extra.price ?? 0);
    }, 0);

    const basePrice =
      price + caflerFee + specificHoursPrice + addedNonScheduledMotPrice + urgencyFee;

    const iva = this.summaryData.serviceConfiguration?.fuelType ? 0 : this.getIva(basePrice);
    const promotionCodeValue = this.promotionCodeValue ?? 0;

    const total = basePrice + monetaryAmount + toppingsTotal + iva - promotionCodeValue;

    return parseFloat(total.toFixed(2));
  }

  getDisplayedTotalValue(): number {
    if (
      this.summaryData.Coupons &&
      this.summaryData.Coupons.length > 0 &&
      this.summaryData.Coupons[0].CouponInformation?.TotalOrderAmount
    ) {
      if (this.summaryData.toppingInformation?.length === 0) {
        return this.summaryData.Coupons[0].CouponInformation.TotalOrderAmount;
      }
    }

    if (this.summaryData.TotalOrderAmount) {
      return this.summaryData.TotalOrderAmount;
    }

    return this.getTotalValue();
  }

  private getIva(value: number): number {
    return parseFloat((value * 0.21).toFixed(2));
  }

  isOutOfRange(): boolean {
    return this.summaryData.productInformation.product?.outOfRange ?? false;
  }

  isTransit(): boolean {
    return (
      this.summaryData.productInformation.product?.ProductKey === this.personalizationId.Transit
    );
  }

  isTyre(): boolean {
    return this.summaryData.productInformation.product?.ProductKey === this.personalizationId.Tyres;
  }

  isAddedNonScheduledMot360(): boolean {
    const itvPersonalizationIds = [
      PersonalizationId.Itv360,
      PersonalizationId.PreMot360,
      PersonalizationId.ItvDiesel360,
      PersonalizationId.ItvMotorcycle360,
      PersonalizationId.ItvccMotorcycle360,
    ];
    let isItv360 = false;
    if (this.summaryData.productInformation?.product?.addedNonScheduledMotProductKey) {
      const productkKey =
        this.summaryData.productInformation?.product?.addedNonScheduledMotProductKey;
      isItv360 = productkKey
        ? itvPersonalizationIds.includes(productkKey as PersonalizationId)
        : false;
    }
    return isItv360;
  }

  getNonScheduledTitle(): string {
    const key = this.summaryData.serviceConfiguration?.hasAppointment
      ? 'scheduled-mot'
      : 'non-scheduled-mot';
    return this.translate.instant(key);
  }
}
