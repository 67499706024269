<div
  class="modal fade"
  id="politicaDeCancelacionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="politicaDeCancelacionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content contact-modal">
      <div class="w-100 d-flex justify-content-end">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="content">
        <div class="contact-box">
          <h5 id="politicaDeCancelacionModalLabel">{{ 'cancelacionTitle' | translate }}</h5>
          <p>
            {{ 'cancelacionDescription' | translate }}
            <a
              href="https://cafler.com/es/terminos-y-condiciones/"
              target="_blank"
              class="cancellationPolicy-a"
              >{{ 'cancelacionPolicy' | translate }}</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
