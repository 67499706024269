import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnChanges {
  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter<boolean>();

  constructor(private elRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isActive']) {
      this.updateToggleState();
    }
  }

  toggleChanged() {
    this.isActive = !this.isActive;
    this.isActiveChange.emit(this.isActive);
  }

  private updateToggleState() {
    const checkbox = this.elRef.nativeElement.querySelector(
      'input[type="checkbox"]'
    ) as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = this.isActive;
    }
  }
}
