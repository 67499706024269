<div class="d-flex flex-column">
  <app-alert [text]="'No se ha encontrado ningún servicio en las coordenadas' | translate" [type]="2"
    *ngIf="showUpsertVehicleAlert" (closeAlertEvent)="onAlertClosed()" [isForDialog]="true"></app-alert>
  <div class="section-container section-divider gap-30 d-flex flex-column">
    <div class="d-flex flex-column gap-15">
      <div class="d-flex flex-row justify-content-end align-items-center">
        <img src="/assets/icons/close-modal.svg" alt="Close icon" class="icon-size" (click)="onClose()"
          (keyup)="onClose()" tabindex="0" />
      </div>
      <span class="title" *ngIf="currentIsFromMyServicesStep === 0 && !isVehicleStorage()">{{
        'return_transfer' | translate
        }}</span>
      <span class="title" *ngIf="currentIsFromMyServicesStep === 0 && isVehicleStorage() && !data?.isTowTruckService">{{
        'takeoutVehicle' | translate
        }}</span>
      <span class="title" *ngIf="currentIsFromMyServicesStep === 0 && isVehicleStorage() && data?.isTowTruckService">{{
        'Sacar vehículo con grúa' | translate
        }}</span>
      <span *ngIf="currentIsFromMyServicesStep === 0 && !isVehicleStorage()">{{
        'return_transfer_description' | translate
        }}</span>
      <span *ngIf="currentIsFromMyServicesStep === 0 && isVehicleStorage() && !data?.isTowTruckService">{{
        'takeout_vehicle_description' | translate
        }}</span>
      <span *ngIf="currentIsFromMyServicesStep === 0 && isVehicleStorage() && data?.isTowTruckService">{{
        'Descripción para sacar vehículo de la campa mediante grúa' | translate
        }}</span>
      <span class="title" *ngIf="currentIsFromMyServicesStep !== 0 && !isVehicleStorage()">{{
        'return_transfer_title' | translate
        }}</span>
    </div>
    <app-stepper [steps]="isFromMyServicesSteps" [activeStep]="currentIsFromMyServicesStep" [isVertical]="true"
      *ngIf="data.isFromMyServices"></app-stepper>
    <ng-container *ngIf="currentIsFromMyServicesStep === 0">
      <div class="disclaimer d-flex flex-row" *ngIf="!isVehicleStorage()">
        <img [src]="'/assets/icons/information-circle.svg'" alt="Info icon" class="icon-size" />
        <span [innerHTML]="'return_transfer_disclaimer' | translate"></span>
      </div>
      <div class="address-container">
        <span class="route-title">{{ 'your_route' | translate }}</span>
        <app-address-box [summaryData]="swappedSummaryData" [routeOnly]="true"
          [canChangeDeliveryAddress]="isVehicleStorage()"
          (deliveryAddressChanged)="onDeliveryAddressChange($event)"></app-address-box>
      </div>
      <div class="d-flex gap-15 flex-column"
        *ngIf="(isVehicleStorage() && swappedSummaryData.locationInfo?.destinationAddress) || !isVehicleStorage()">
        <h4 class="d-flex align-items-center sub-title">
          <img src="/assets/icons/new-service-calendar.svg" alt="Calendar icon" />&nbsp;
          {{ 'pickupDateAndTime' | translate }}
        </h4>

        <div class="summary-information-box gap-10 d-flex flex-column">
          <label for="pickup-date" class="bold-sm">{{ 'pickup_day' | translate }}</label>
          <div class="calendar-picker-container w-100">
            <input type="text" class="form-control calendar-picker" #dp="bsDatepicker" bsDatepicker
              [daysDisabled]="[6, 0]" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY',
                containerClass: 'theme-default',
                minDate: minDate,
              }" autocomplete="off" (bsValueChange)="onPickupDateChange($event)" required placeholder="DD/MM/YYYY" />
            <div class="calendar-icon"></div>
          </div>
        </div>
        <div class="pickup-hour gap-15 d-flex flex-column" *ngIf="pickupDateSelected && !isTransit()">
          <div class="summary-information-box gap-10 fw-600 d-flex flex-column">
            <label for="pickup-date" class="bold-sm">{{ 'pickupTime' | translate }}</label>
            <select class="select-container w-100 padding-left" name="pickup-date" id="pickup-date"
              [(ngModel)]="isSpecificHours" (ngModelChange)="onTimeSlotChange($event)">
              <option [ngValue]="false">{{ 'scheduleWithTimeSlots' | translate }}</option>
              <option [ngValue]="true">
                {{ 'scheduleWithSpecificTimesPrice1' | translate }}
              </option>
            </select>
          </div>

          <div *ngIf="noAvailableHours" class="no-hours-message">
            {{ 'noAvailableHoursMessage' | translate }}
          </div>

          <div class="d-flex flex-column justify-content-between gap-15" *ngIf="!isSpecificHours && !noAvailableHours">
            <div class="d-flex justify-content-between w-100 gap-15">
              <div class="summary-information-box gap-10 fw-600 d-flex flex-column w-100">
                <label for="pickupTime" class="bold-sm">{{ 'from' | translate }}</label>
                <div class="time-selector gap-10 d-flex">
                  <div class="select-container d-flex flex-column align-items-center w-100">
                    <div class="clock"></div>
                    <select name="pickupTime" id="pickupTime" [(ngModel)]="selectedStartHour"
                      (ngModelChange)="handleStartTimeChange($event)" class="custom-select d-flex" required>
                      <option *ngFor="let hour of availableStartHours" [ngValue]="hour">
                        {{ hour.Text }}
                      </option>
                    </select>
                    <div class="chevron"></div>
                  </div>
                </div>
              </div>

              <div class="summary-information-box gap-10 fw-600 d-flex flex-column w-100">
                <label for="endHour" class="bold-sm">{{ 'until' | translate }}</label>
                <div class="time-selector gap-10 w-100 d-flex" [ngClass]="{ 'gray-bg': noAvailableEndHours }">
                  <div class="select-container d-flex flex-column align-items-center w-100">
                    <div class="clock"></div>
                    <select name="endHour" id="endHour" [(ngModel)]="selectedEndHour"
                      (ngModelChange)="updateSelectedHours($event)" class="custom-select">
                      <option *ngFor="let hour of availableEndHours" [ngValue]="hour">
                        {{ hour.Text }}
                      </option>
                    </select>
                    <div class="chevron"></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="noAvailableEndHours" class="warning-message no-hours-message">
              {{ warningMessage }}
            </div>
          </div>

          <div class="time-selector gap-10 w-100" *ngIf="isSpecificHours && !noAvailableHours">
            <div class="custom-select-container">
              <button class="custom-select custom-select-hour" (click)="toggleDropdown()">
                {{ selectedHourPair ? selectedHourPair.displayText : 'Selecciona una hora' }}
              </button>
              <div class="custom-options" [ngClass]="{ show: showDropdown }">
                <div *ngFor="let hour of availableHours" [ngClass]="{ selected: hour === selectedHourPair }"
                  (click)="selectHour(hour)" (keydown)="selectHour(hour)" tabindex="0" class="custom-option">
                  {{ hour.displayText }}
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex urgency-fee-disclaimer">
            <img src="/assets/icons/shield-exclamation.svg" class="icon-size" alt="shield" />
            <span>{{ 'urgency_fee_disclaimer' | translate }}</span>
          </div>
        </div>
      </div>

      <form [formGroup]="pickUpContactSamePersonFormGroup" (ngSubmit)="onSubmit()"
        *ngIf="isVehicleStorage() && swappedSummaryData.locationInfo?.formattedStartTime && swappedSummaryData.locationInfo?.formattedEndTime">
        <div class="gap-15 d-flex flex-column section-divider">
          <h4 class="d-flex justify-content-start align-items-center">
            <img src="/assets/icons/new-service-user.svg" alt="User icon" />
            <span>{{ 'deliveryContact' | translate }}</span>
          </h4>
          <div class="d-flex flex-column data-box w-100">
            <label>{{ 'select_contact_type' | translate }}</label>
            <div class="contact-selector d-flex flex-column align-items-start justify-content-center" [ngClass]="{
                  checked:
                    pickUpContactSamePersonFormGroup.get('isContactExternal')?.value === false,
                  'error-input':
                    pickUpContactSamePersonInvalidForm &&
                    pickUpContactSamePersonFormGroup.get('isContactExternal')?.invalid,
                }" (click)="selectOption(ContactType.PickupAndDeliver, false)"
              (keydown)="selectOption(ContactType.PickupAndDeliver, false)" tabindex="0">
              <input type="radio" (click)="selectOption(ContactType.PickupAndDeliver, false)"
                [checked]="pickUpContactSamePersonFormGroup.get('isContactExternal')?.value === false"
                id="pickup-contact-same-person" />
              <div class="contact-text">
                <label>{{ 'corporate_contact' | translate }}</label>
                <span>{{ 'corporate_contact_description' | translate }}</span>
              </div>
            </div>
            <div *ngIf="
                  pickUpContactSamePersonFormGroup.get('isContactExternal')?.invalid &&
                  pickUpContactSamePersonInvalidForm
                " class="error">
              {{ 'required' | translate }}
            </div>
          </div>

          <div class="d-flex flex-column data-box w-100">
            <div class="contact-selector d-flex flex-column align-items-start justify-content-center" [ngClass]="{
                  checked:
                    pickUpContactSamePersonFormGroup.get('isContactExternal')?.value === true,
                  'error-input':
                    pickUpContactSamePersonInvalidForm &&
                    pickUpContactSamePersonFormGroup.get('isContactExternal')?.invalid,
                  'disabled-contact-selector': isTransit(),
                }" (click)="selectOption(ContactType.PickupAndDeliver, true)"
              (keydown)="selectOption(ContactType.PickupAndDeliver, true)" tabindex="0">
              <input type="radio" [checked]="pickUpContactSamePersonFormGroup.get('isContactExternal')?.value === true"
                (click)="selectOption(ContactType.PickupAndDeliver, true)" id="pickup-contact-same-person" />
              <div class="contact-text">
                <label>{{ 'particular_contact' | translate }}</label>
                <span>{{ 'particular_contact_description' | translate }}</span>
              </div>
            </div>
            <div *ngIf="
                  pickUpContactSamePersonFormGroup.get('isContactExternal')?.invalid &&
                  pickUpContactSamePersonInvalidForm
                " class="error">
              {{ 'required' | translate }}
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center gap-15">
            <div class="data-box d-flex flex-column align-items-start align-self-stretch w-50">
              <label for="pickup-contact-name">{{ 'firstName' | translate }}</label>
              <input type="text" name="pickup-contact-name" formControlName="contactName" placeholder="Dani"
                class="active-input w-100 d-flex align-items-center" required [ngClass]="{
                  'error-input':
                    pickUpContactSamePersonInvalidForm &&
                    !pickUpContactSamePersonFormGroup.get('contactName')?.valid,
                }" />
              <div *ngIf="
                  pickUpContactSamePersonFormGroup.get('contactName')?.invalid &&
                  pickUpContactSamePersonInvalidForm
                " class="error">
                {{ 'required' | translate }}
              </div>
            </div>
            <div class="data-box d-flex flex-column align-items-start align-self-stretch w-50">
              <label for="pickup-contact-lastname">{{ 'last_name' | translate }}</label>
              <input type="text" name="pickup-contact-lastname" formControlName="contactLastName"
                class="active-input w-100 d-flex align-items-center" placeholder="Parrales Rosa" required [ngClass]="{
                  'error-input':
                    pickUpContactSamePersonInvalidForm &&
                    !pickUpContactSamePersonFormGroup.get('contactLastName')?.valid,
                }" />
              <div *ngIf="
                  pickUpContactSamePersonFormGroup.get('contactLastName')?.invalid &&
                  pickUpContactSamePersonInvalidForm
                " class="error">
                {{ 'required' | translate }}
              </div>
            </div>
          </div>
          <div class="phone-box d-flex flex-column align-items-start">
            <label for="pickup-contact-phone">{{ 'mobile_number' | translate }}</label>
            <div class="form-group w-100 d-flex flex-column data-box">
              <div class="input-group d-flex align-items-start">
                <select class="prefix-select" name="prefix" formControlName="phoneRegion">
                  <option value="esp">+34</option>
                  <option value="gbr">+44</option>
                  <option value="fra">+33</option>
                </select>
                <input type="tel" class="form-control phone-input white-group-box w-100 d-flex align-items-center"
                  id="phone" name="phone" formControlName="contactPhone" placeholder="{{ 'mobile_number' | translate }}"
                  required [ngClass]="{
                    'error-input':
                      pickUpContactSamePersonInvalidForm &&
                      !pickUpContactSamePersonFormGroup.get('contactPhone')?.valid,
                  }" />
              </div>
              <div *ngIf="
                  pickUpContactSamePersonFormGroup.get('contactPhone')?.invalid &&
                  pickUpContactSamePersonInvalidForm
                " class="error">
                {{ getErrorMessage('contactPhone', 'pickUpContactSamePersonFormGroup') }}
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="form">
        <h4 class="d-flex align-items-center justify-content-start gap-10">
          <img src="/assets/icons/new-service-chat.svg" alt="" />
          <span class="sub-title">{{ 'additionalInstructions' | translate }}</span>
          <small class="sub-title">({{ 'optional' | translate }})</small>
        </h4>
        <div class="d-flex flex-column w-100">
          <textarea [formControlName]="'extraInfo'" placeholder="{{ 'write_here' | translate }}"
            class="inst-adicional d-flex flex-column align-items-end justify-content-end" (keydown)="onKeyDown($event)"
            maxlength="{{ maxChars }}"></textarea>
          <p class="d-flex w-100 justify-content-end align-items-center gap-10 m-0">
            {{ charCount() }}
          </p>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="currentIsFromMyServicesStep === 1">
      <app-extras-funnel-dialog [summaryData]="swappedSummaryData"
        [mappedToppings]="mappedToppings"></app-extras-funnel-dialog>
    </ng-container>

    <ng-container *ngIf="currentIsFromMyServicesStep === 2">
      <app-summary-funnel-dialog [summaryData]="swappedSummaryData"></app-summary-funnel-dialog>
    </ng-container>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center button-container">
    <button *ngIf="
        (isVehicleStorage() && currentIsFromMyServicesStep > 1) ||
        (!isVehicleStorage() && currentIsFromMyServicesStep > 0)
      " class="btn btn-secondary btn-lg mr-2 back-button" (click)="onBack()">
      {{ 'back' | translate }}
    </button>
    <button class="btn btn-primary btn-lg btn-block" [ngClass]="{ 'w-100': currentIsFromMyServicesStep === 0 }"
      (click)="onSubmit()">
      {{
      currentIsFromMyServicesStep < 2 ? ( 'continue' | translate ) : !isVehicleStorage() ? ('add_return_to_origin' |
        translate) : data.isTowTruckService ? ('request_budget' | translate) : ('add' | translate) }} </button>
  </div>
</div>