<div class="topbar">
  <img src="assets/images/cafler-transitalia.png" alt="Logo" routerlink="/" class="logo" tabindex="0"
    ng-reflect-router-link="/" />
  <!-- <img src="assets/images/logo_Avisa_Servicios_Integrales.png" alt="Logo" routerlink="/" class="logo" tabindex="0"
    ng-reflect-router-link="/" /> -->

  <div class="sections">
    <button class="section-button" [ngClass]="{
        active: checkSamePath('new-service') || checkSamePath('new-service'),
      }" [routerLink]="['/new-service']">
      <img src="/assets/icons/plus-circle-black-fill.svg" alt="" />
      <div class="section-button-text">{{ 'request_service' | translate }}</div>
    </button>
  </div>

  <div class="profile-card">
    <div class="question-mark">
      <div class="icon-container" [class.selected]="isSelected" (mouseover)="onHover(true)"
        (mouseleave)="onHover(false)" (click)="onSelect()">
        <img class="icon black" src="../../assets/icons/question-mark-circle.svg" alt="Default Icon"
          (click)="toggleQuestion()" />
        <img class="icon hover" src="../../assets/icons/question-mark-circle-hover.svg" alt="Hover Icon" />
        <img class="icon selected" src="../../assets/icons/question-mark-circle-green.svg" alt="Selected Icon"
          (click)="toggleQuestion()" />
      </div>
      <div class="dropdown-help" [class.show]="isQuestionOpen">
        <div class="dropdown-item" (click)="redirectHelp()">
          <div class="item-icon">
            <img src="../../assets/icons/question-mark-circle.svg" />
          </div>
          <span class="item-text">{{ 'helpCenter' | translate }}</span>
        </div>
        <div class="dropdown-item" (click)="redirectTutorials()">
          <div class="item-icon">
            <img src="../../assets/icons/video-camera.svg" />
          </div>
          <span class="item-text">{{ 'tutorials' | translate }}</span>
        </div>
        <div class="dropdown-item" (click)="openModal($event)">
          <div class="item-icon">
            <img src="../../assets/icons/chat.svg" />
          </div>
          <span class="item-text">{{ 'contactSupport' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="profile-info">
      <p class="profile-name">{{ userName }}</p>
      <p class="profile-email">{{ userEmail }}</p>
    </div>
    <div class="profile-avatar">
      <div class="button-arrow" [ngClass]="{ rotated: isRotated }" (click)="toggleDropdown()">
        <img src="../../assets/icons/chevron-left-dark.svg" alt="" />
      </div>
      <img src="{{ userImg }}" alt="User Avatar" />
    </div>
  </div>

  <!--Dropdown menu-->
  <div class="dropdown" [class.show]="isDropdownOpen">
    <div class="dropdown-item" [routerLink]="['/my-account']">
      <div class="item-icon">
        <img src="../../assets/icons/user-icon.svg" />
      </div>
      <span class="item-text">{{ 'my_profile' | translate }}</span>
    </div>
    <div class="dropdown-item" [routerLink]="['/settings']">
      <div class="item-icon">
        <img src="../../assets/icons/settings-icon.svg" />
      </div>
      <span class="item-text">{{ 'settings' | translate }}</span>
    </div>
    <div class="dropdown-item logout" [routerLink]="['/logout']">
      <div class="item-icon">
        <img src="../../assets/icons/logout-icon.svg" />
      </div>
      <span class="item-text">{{ 'logout' | translate }}</span>
    </div>
  </div>
</div>