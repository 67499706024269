import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-modal-promotion-code',
  templateUrl: './modal-promotion-code.component.html',
  styleUrls: ['./modal-promotion-code.component.scss'],
})
export class ModalPromotionCodeComponent {
  @Input() promotionCode = '';
  @Output() promotionCodeChange = new EventEmitter<string>();
  @Input() promotionCodeValidator = false;
  @Output() validatePromoCode = new EventEmitter<void>();
  @ViewChild('closeModal') closeModal: ElementRef | undefined;

  onPromotionCodeChange(value: string) {
    this.promotionCode = value;
    this.promotionCodeChange.emit(value);
  }

  validatePromotionCode(): void {
    this.validatePromoCode.emit();
  }
}
