<div class="step1">
  <div class="d-flex">
    <app-stepper [steps]="steps" [activeStep]="currentStep"> </app-stepper>
    <div class="content-box">
      <ng-container [ngSwitch]="this.summaryData.productInformation.product?.ProductKey">
        <app-refill
          *ngSwitchCase="personalizationId.Refuel"
          (changedSummaryDataEvent)="onChangeSummaryData($event)"
          (subCategorySelected)="handleSelectedSubCategory($event)"
          [summaryData]="summaryData"
          [preBooking]="preBooking.bind(this)"
        ></app-refill>
        <app-change-name *ngSwitchCase="personalizationId.OwnershipChange"></app-change-name>
        <app-tow
          *ngSwitchCase="personalizationId.TowTruck"
          (selectionChanged)="onVehicleMovementStatus($event)"
          (subCategorySelected)="handleSelectedSubCategory($event)"
        ></app-tow>
        <app-tyre
          *ngSwitchCase="personalizationId.Tyres"
          (selectionChanged)="onTyreConfigurationChange($event)"
          (subCategorySelected)="handleSelectedSubCategory($event)"
          [finalProductSelected]="finalProductSelected"
          [summaryData]="summaryData"
          [preBooking]="preBooking.bind(this)"
        ></app-tyre>
        <app-ask-for-everything *ngSwitchCase="personalizationId.OpenForm"></app-ask-for-everything>
      </ng-container>
      <app-itv
        *ngIf="
          summaryData.productInformation.product &&
          summaryData.productInformation.product.ProductKey &&
          [
            personalizationId.TechnicalInspection.toString(),
            personalizationId.PreMot.toString(),
            personalizationId.MotHomologation.toString(),
            personalizationId.ItvMotorcycle.toString(),
            personalizationId.Itv.toString(),
            personalizationId.Itv360.toString(),
            personalizationId.PreMot360.toString(),
            personalizationId.ItvMotorcycle360.toString(),
            personalizationId.ItvccMotorcycle360.toString(),
          ].includes(summaryData.productInformation.product.ProductKey)
        "
        (changedSummaryDataEvent)="onChangeSummaryData($event)"
        (selectionChanged)="onTechnicalInspectionChange($event)"
        (subCategorySelected)="handleSelectedSubCategory($event)"
        [finalProductSelected]="finalProductSelected"
        [summaryData]="summaryData"
        [preBooking]="preBooking.bind(this)"
      ></app-itv>
      <app-new-service-personalization-template
        [summaryData]="summaryData"
        (selectedSubcategoryEvent)="handleSelectedSubCategory($event)"
        *ngIf="
          !isNotConventionalPersonalization &&
          this.summaryData.productInformation.product?.ProductKey !== personalizationId.PreMot &&
          this.summaryData.productInformation.product?.ProductKey !==
            personalizationId.MotHomologation &&
          this.summaryData.productInformation.product?.ProductKey !==
            personalizationId.ItvMotorcycle &&
          this.summaryData.productInformation.product?.ProductKey !== personalizationId.Itv &&
          this.summaryData.productInformation.product?.ProductKey !== personalizationId.PreMot360 &&
          this.summaryData.productInformation.product?.ProductKey !==
            personalizationId.ItvMotorcycle360 &&
          this.summaryData.productInformation.product?.ProductKey !==
            personalizationId.ItvccMotorcycle360 &&
          this.summaryData.productInformation.product?.ProductKey !== personalizationId.Itv360 &&
          this.summaryData.productInformation.product?.ProductKey !==
            personalizationId.TechnicalInspection
        "
      ></app-new-service-personalization-template>
    </div>
  </div>
</div>
