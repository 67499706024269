<div class="d-flex flex-column dialog-container">
  <div class="title-and-description d-flex">
    <img src="../../../../../assets/icons/new-service-calendar.svg" alt="calendar-icon" />
    <h3>{{ 'summary' | translate }}</h3>
  </div>
  <!-- <div class="d-flex stepper-container">
    <span>{{ 'data' | translate }}</span> <span class="chevron">></span>
    <span>{{ 'extras' | translate }} </span><span class="chevron">></span>
    <span>{{ 'summary' | translate }}</span>
  </div> -->

  <div class="funnel-summary-container">
    <div class="d-flex flex-column funnel-summary-box">
      <div class="title">
        {{ 'nextService' | translate }}
      </div>
      <app-address-box [summaryData]="summaryData"></app-address-box>
      <app-additional-info [summaryData]="summaryData"></app-additional-info>
      <app-contact-info [summaryData]="summaryData"></app-contact-info>
      <app-comments [summaryData]="summaryData" [isFunnelDialog]="true"></app-comments>
      <app-pricing-summary [summaryData]="summaryData" [currentStep]="6" [isFunnelDialog]="true"></app-pricing-summary>
    </div>

    <app-cancellation-policy [summaryData]="summaryData" [currentStep]="6"></app-cancellation-policy>
  </div>
</div>