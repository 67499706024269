import { Component, Input } from '@angular/core';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';

@Component({
  selector: 'app-address-box',
  templateUrl: './address-box.component.html',
  styleUrls: ['./address-box.component.scss'],
})
export class AddressBoxComponent {
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() routeOnly = false;

  formattedDateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  handleStartDate(): string {
    if (this.summaryData.locationInfo?.serviceStartDate)
      return this.summaryData.locationInfo?.formattedStartDate as string;
    if (this.summaryData.serviceConfiguration?.appointmentDate)
      return new Date(
        this.summaryData.serviceConfiguration?.appointmentDate as string
      ).toLocaleDateString('es-ES', this.formattedDateOptions);
    else return '';
  }

  handleStartTime(): string {
    if (!this.summaryData.locationInfo?.formattedEndTime) return '';
    if (
      !this.summaryData.serviceConfiguration?.appointmentTime &&
      this.summaryData.locationInfo?.formattedStartTime
    )
      return (
        this.summaryData.locationInfo?.formattedStartTime +
        '-' +
        this.summaryData.locationInfo?.formattedEndTime
      );
    else
      return this.subtractOneHour(this.summaryData.serviceConfiguration?.appointmentHour as string);
  }

  subtractOneHour(timeStr: string): string {
    if (!timeStr) return '';
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setHours(date.getHours() - 1);
    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${newHours}:${newMinutes}`;
  }
}
