import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject = new BehaviorSubject<any | null>(null);

  constructor(private msalService: MsalService) {
    this.emitUserChanges();
  }

  private emitUserChanges(): void {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account) {
      this.userSubject.next({
        name: account.name,
        email: account.username,
        img: '../../assets/icons/navbar/nav-user-icon.svg',
        // img: '../../assets/images/logo_Avisa_Servicios_Integrales.png',

      });
    } else {
      this.userSubject.next(null);
    }
  }

  getUserInfo(): Observable<any | null> {
    return this.userSubject.asObservable();
  }

  refreshUserInfo(): void {
    this.emitUserChanges();
  }

  clearUserInfo(): void {
    this.userSubject.next(null);
  }
}
