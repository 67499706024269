import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription, finalize } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModalExtraInsuranceVehicleComponent } from 'src/app/pages/new-service/components/new-service-extras/components/modal-extra-insurance-vehicle/modal-extra-insurance-vehicle.component';
import { ModalExtraRefillComponent } from 'src/app/pages/new-service/components/new-service-extras/components/modal-extra-refill/modal-extra-refill.component';
import { ModalExtraSustitutionVehicleComponent } from 'src/app/pages/new-service/components/new-service-extras/components/modal-extra-sustitution-vehicle/modal-extra-sustitution-vehicle.component';
import { ModalExtraWashingComponent } from 'src/app/pages/new-service/components/new-service-extras/components/modal-extra-washing/modal-extra-washing.component';
import {
  SummaryData,
  ToppingInformation,
  ServiceConfiguration,
} from 'src/app/pages/new-service/interfaces/SummaryData';
import { MappedTopping } from 'src/app/pages/new-service/interfaces/Topping';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-extras-funnel-dialog',
  templateUrl: './extras-funnel-dialog.component.html',
  styleUrls: ['./extras-funnel-dialog.component.scss'],
})
export class ExtrasFunnelDialogComponent {
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() mappedToppings: MappedTopping[] = [];

  form: FormGroup;
  private subscriptions: Subscription[] = [];
  steps: string[] = [];
  selectedWashProductId: string | null = null;

  constructor(
    private fb: FormBuilder,
    private funnelService: FunnelService,
    private loader: LoaderService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) {
    this.form = this.fb.group({
      noExtras: new FormControl(true),
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.updateTogglesFromSummaryData();
  }

  private initializeForm(): void {
    const controls: { [key: string]: FormControl } = {
      noExtras: new FormControl(true),
    };

    this.mappedToppings.forEach((topping) => {
      controls[topping.id] = new FormControl(false);
    });

    this.form = this.fb.group(controls);
  }

  private updateTogglesFromSummaryData(): void {
    const currentSummaryData = this.getCurrentSummaryData();
    const formValues: { [key: string]: boolean } = { noExtras: true };

    if (currentSummaryData.toppingInformation && currentSummaryData.toppingInformation.length > 0) {
      currentSummaryData.toppingInformation.forEach((topping) => {
        const mappedTopping = this.mappedToppings.find((t) => t.titleId === topping.title);
        if (mappedTopping) {
          formValues[mappedTopping.id] = true;
          if (mappedTopping.id === 'wash') {
            this.selectedWashProductId = mappedTopping.products[0].id;
          }
        }
      });
      formValues['noExtras'] = false;
    }

    this.form.patchValue(formValues, { emitEvent: false });
    this.cdr.detectChanges();
  }

  onToggleChange(id: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    const topping = this.mappedToppings.find((t) => t.id === id);

    if (id === 'noExtras') {
      this.handleNoExtrasToggle(isChecked);
    } else if (topping?.hasModal && isChecked) {
      event.preventDefault();
      this.openModal(id);
    } else {
      isChecked ? this.addExtra(id) : this.removeExtra(id);
    }
  }

  private openModal(id: string): void {
    const currentSummaryData = this.getCurrentSummaryData();
    const data = {
      summaryData: currentSummaryData,
      mappedTopping: this.mappedToppings.find((t) => t.id === id),
    };
    const modalConfig: MatDialogConfig = {
      data,
      width: '450px',
      height: '61vh',
      viewContainerRef: this.viewContainerRef,
      disableClose: true,
    };

    const modalComponentMap: { [key: string]: ComponentType<any> } = {
      insurance: ModalExtraInsuranceVehicleComponent,
      refueling: ModalExtraRefillComponent,
      replacement_vehicle: ModalExtraSustitutionVehicleComponent,
      wash: ModalExtraWashingComponent,
    };

    const dialogRef = this.dialog.open(modalComponentMap[id], modalConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result.incompletedTopping) {
        this.form.get(id)?.setValue(false);
      } else {
        if (id === 'wash') {
          this.selectedWashProductId = result.selectedProductId;
        }
        this.updateCartInfo(result, data.mappedTopping);
      }
    });
  }

  private handleNoExtrasToggle(isNoExtrasChecked: boolean): void {
    if (isNoExtrasChecked) {
      Object.keys(this.form.controls).forEach((key) => {
        if (key !== 'noExtras') {
          this.form.get(key)?.setValue(false, { emitEvent: false });
        }
      });
      this.getCurrentSummaryData().toppingInformation = [];
      this.removeAllExtras();
    }
    this.form.get('noExtras')?.setValue(isNoExtrasChecked, { emitEvent: false });
    this.cdr.detectChanges();
  }

  private addExtra(id: string, modalResult?: any): void {
    const currentSummaryData = this.getCurrentSummaryData();
    if (!currentSummaryData?.orderHash) {
      console.error('Order hash is not defined');
      return;
    }

    const topping = this.mappedToppings.find((t) => t.id === id);
    if (!topping) {
      console.error('Topping not found');
      return;
    }

    this.loader.show();

    const product = topping.products[0];
    const subscription = this.funnelService
      .addTopping(
        product.id,
        currentSummaryData.orderHash,
        modalResult?.serviceConfiguration || {},
        topping.title
      )
      .pipe(
        finalize(() => {
          this.loader.hide();
          this.cdr.detectChanges();
        })
      )
      .subscribe({
        next: (response) => {
          this.updateCartInfo(response, topping);
          this.form.get('noExtras')?.setValue(false, { emitEvent: false });
          this.form.get(id)?.setValue(true, { emitEvent: false });
        },
        error: (error) => {
          console.error('Error adding topping:', error);
          this.form.get(id)?.setValue(false, { emitEvent: false });
        },
      });

    this.subscriptions.push(subscription);
  }

  private removeExtra(id: string): void {
    const currentSummaryData = this.getCurrentSummaryData();
    const topping = this.mappedToppings.find((t) => t.id === id);
    if (!topping) {
      console.error('Topping not found');
      return;
    }

    this.loader.show();

    const productId = id === 'wash' ? this.selectedWashProductId : topping.products[0].id;

    if (!productId) {
      console.error('Product ID not found');
      this.loader.hide();
      return;
    }

    this.funnelService
      .removeTopping(currentSummaryData.orderHash!, productId)
      .pipe(
        finalize(() => {
          this.loader.hide();
          this.cdr.detectChanges();
        })
      )
      .subscribe({
        next: (response) => {
          if (id === 'wash') {
            this.selectedWashProductId = null;
          }
          this.updateCartInfo(response, topping, true);
          this.updateTogglesFromSummaryData();
        },
        error: (error) => {
          console.error('Error removing topping:', error);
          this.form.get(id)?.setValue(true, { emitEvent: false });
        },
      });
  }

  removeAllExtras(): void {
    const currentSummaryData = this.getCurrentSummaryData();
    this.loader.show();

    const subscription = this.funnelService
      .removeTopping(currentSummaryData.orderHash!, undefined, true)
      .pipe(
        finalize(() => {
          this.loader.hide();
          this.cdr.detectChanges();
        })
      )
      .subscribe({
        next: (response) => {
          this.updateCartInfo(response);
          currentSummaryData.toppingInformation = [];
          this.selectedWashProductId = null;
          this.updateTogglesFromSummaryData();
        },
        error: (error) => {
          console.error('Error removing all toppings:', error);
          this.form.get('noExtras')?.setValue(false, { emitEvent: false });
          this.cdr.detectChanges();
        },
      });

    this.subscriptions.push(subscription);
  }

  private updateCartInfo(response: any, mappedTopping?: MappedTopping, isRemoving = false): void {
    const currentSummaryData = this.getCurrentSummaryData();

    if (mappedTopping) {
      if (isRemoving) {
        currentSummaryData.toppingInformation = currentSummaryData.toppingInformation?.filter(
          (topping) => !this.isToppingRelated(topping, mappedTopping)
        );
      } else {
        const newToppingInfoArray = this.convertToToppingInformation(
          mappedTopping,
          response.Products
        );
        if (!currentSummaryData.toppingInformation) {
          currentSummaryData.toppingInformation = [];
        }
        this.updateToppingInformation(currentSummaryData.toppingInformation, newToppingInfoArray);
      }

      this.form
        .get('noExtras')
        ?.setValue(currentSummaryData.toppingInformation!.length === 0, { emitEvent: false });
    }

    this.updateProductInformation(currentSummaryData, response);
    this.updateOrderInformation(currentSummaryData, response);
    this.cdr.detectChanges();
  }

  private updateToppingInformation(
    existingToppings: ToppingInformation[],
    newToppings: ToppingInformation[]
  ): void {
    newToppings.forEach((newToppingInfo) => {
      const existingIndex = existingToppings.findIndex((t) => t.title === newToppingInfo.title);
      if (existingIndex !== -1) {
        existingToppings[existingIndex] = newToppingInfo;
      } else {
        existingToppings.push(newToppingInfo);
      }
    });
  }

  private updateProductInformation(summaryData: SummaryData, response: any): void {
    const motProductKeys = [
      'mot-fuel-car-es',
      'mot-diesel-car-es',
      'mot-electric-car-es',
      'mot-motorcycle-es',
    ];
    const addedNonScheduledMotPrice = response.Products.find((product: any) =>
      motProductKeys.includes(product.ProductKey)
    );
    if (summaryData.productInformation?.product) {
      summaryData.productInformation.product.CaflerFee =
        response.Fees.find((fee: any) => fee.Key === 'cafler-fee')?.Amount || 0;
      summaryData.productInformation.product.addedNonScheduledMotPrice =
        addedNonScheduledMotPrice?.Amount;
    }
  }

  private updateOrderInformation(summaryData: SummaryData, response: any): void {
    summaryData.TotalOrderAmount = response.TotalOrderAmount;
    summaryData.TotalOrderTaxAmount = response.TotalOrderTaxAmount;
    summaryData.urgencyFee =
      response.Fees.find((fee: any) => fee.Key === 'urgency-fee')?.Amount || 0;
    summaryData.FixedFee = response.Fees.find((fee: any) => fee.Key === 'fixed-fee')?.Amount;
    summaryData.refuelingAdditionalFee =
      response.Fees.find((fee: any) => fee.Key === 'refueling-additional-fee')?.Amount || 0;
    if (response.CouponInformation && response.CouponInformation.DiscountAmount) {
      summaryData.Coupons = summaryData.Coupons || [];
      if (summaryData.Coupons[0]) {
        summaryData.Coupons[0].CouponInformation.DiscountAmount =
          response.CouponInformation.DiscountAmount;
      } else {
        summaryData.Coupons[0] = {
          CouponInformation: response.CouponInformation,
          CouponApplied: true,
          RequestStatus: 'Applied', // Asume que si hay información del cupón, se ha aplicado
        };
      }
    }
  }

  private isToppingRelated(topping: ToppingInformation, mappedTopping: MappedTopping): boolean {
    return mappedTopping.products.some(
      (product) =>
        product.titleId === topping.title ||
        product.relatedProducts?.some((rp) => rp.titleId === topping.title)
    );
  }

  private convertToToppingInformation(
    mappedTopping: MappedTopping,
    responseProducts: any[]
  ): ToppingInformation[] {
    const toppingInformationArray: ToppingInformation[] = [];

    mappedTopping.products.forEach((mainProduct) => {
      const mainResponseProduct = responseProducts.find(
        (p) => p.ProductKey === mainProduct.productKey
      );
      if (mainResponseProduct) {
        toppingInformationArray.push(this.createToppingInfo(mainProduct, mainResponseProduct));
      }

      if (mainProduct.relatedProducts && mainProduct.relatedProducts.length > 0) {
        mainProduct.relatedProducts.forEach((relatedProduct) => {
          const relatedResponseProduct = responseProducts.find(
            (p) => p.ProductKey === relatedProduct.productKey
          );
          if (relatedResponseProduct) {
            toppingInformationArray.push(
              this.createToppingInfo(relatedProduct, relatedResponseProduct)
            );
          }
        });
      }
    });

    return toppingInformationArray;
  }

  private createToppingInfo(product: any, responseProduct: any): ToppingInformation {
    return {
      title: product.titleId,
      description: product.descriptionId,
      price: responseProduct.Amount,
      quantity: 1,
      icon: product.icon,
      serviceConfiguration: {} as ServiceConfiguration,
    };
  }

  private getCurrentSummaryData(): SummaryData {
    return this.summaryData;
  }

  openInfoModal(topping: MappedTopping): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { productKey: topping.id },
      width: '80%',
      maxWidth: '800px',
      panelClass: 'modal-dialog-centered',
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
