<ng-container *ngIf="summaryData && summaryData.vehicleInformation && currentStep > 1">
  <div class="billing-summary-container">
    <div class="billing-summary-header d-flex flex-column w-100">
      <span class="header">{{ 'nextService' | translate }}</span>
      <div class="d-flex">
        <div *ngIf="!moreThanOneLicensePlate" class="vehicle-info">
          <img
            [src]="getChassisTypeIcon(summaryData.vehicleInformation.chassisTypeId)"
            alt="Car"
            class="vehicle-img"
          />
          <div class="vehicle-info-data">
            <p class="title">{{ summaryData.vehicleInformation.brand }}</p>
            <p>
              {{ summaryData.vehicleInformation.licensePlate }} |
              {{ summaryData.vehicleInformation.fuelType }} |
              {{ summaryData.vehicleInformation.chassisType }}
            </p>
          </div>
        </div>
        <div *ngIf="moreThanOneLicensePlate" class="vehicle-info multiple-vehicles">
          <img src="../../assets/icons/card-cars-icon.svg" alt="Car" class="vehicle-img" />
          <div class="vehicle-info-data">
            <p class="title">{{ licensePlateCount }} {{ 'vehicles' | translate }}</p>
            <p class="license-plates">
              {{ 'chassis' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="
        summaryData.locationInfo?.originAddress && summaryData.locationInfo?.destinationAddress
      "
    >
      <div
        class="summary-container"
        [ngClass]="{ 'summary-container-last-step': currentStep === 6 }"
      >
        <app-address-box [summaryData]="summaryData"></app-address-box>
        <app-additional-info [summaryData]="summaryData"></app-additional-info>
        <app-contact-info [summaryData]="summaryData"></app-contact-info>
        <app-comments [summaryData]="summaryData"></app-comments>
        <app-pricing-summary
          *ngIf="!isOpenForm()"
          [summaryData]="summaryData"
          [currentStep]="currentStep"
          [extras]="extras"
          [promotionCode]="promotionCode"
          [promotionCodeValidator]="promotionCodeValidator"
          [promotionCodeValue]="promotionCodeValue"
        ></app-pricing-summary>
        <app-cancellation-policy
          [summaryData]="summaryData"
          [currentStep]="currentStep"
        ></app-cancellation-policy>

        <div
          *ngIf="returnTransferSummaryData.locationInfo?.originAddress"
          class="summary-container-transfer"
        >
          <app-address-box [summaryData]="returnTransferSummaryData"></app-address-box>
          <app-additional-info [summaryData]="returnTransferSummaryData"></app-additional-info>
          <app-contact-info [summaryData]="returnTransferSummaryData"></app-contact-info>
          <app-comments [summaryData]="returnTransferSummaryData"></app-comments>
          <app-pricing-summary
            *ngIf="!isOpenForm()"
            [summaryData]="returnTransferSummaryData"
            [currentStep]="currentStep"
            [extras]="extras"
            [promotionCode]="promotionCode"
            [promotionCodeValidator]="promotionCodeValidator"
            [promotionCodeValue]="promotionCodeValue"
          ></app-pricing-summary>
          <app-cancellation-policy
            [summaryData]="returnTransferSummaryData"
            [currentStep]="currentStep"
          ></app-cancellation-policy>
        </div>
      </div>
    </ng-container>

    <app-checkout-container
      class="w-100 h-100"
      *ngIf="
        (currentStep === 6 || currentStep === 4) &&
        !returnTransferSummaryData.locationInfo?.originAddress
      "
      [summaryData]="summaryData"
      [currentStep]="currentStep"
      [disabledButtonLastStep]="disabledButtonLastStep"
      [buttonText]="buttonText"
      [returnTransferSummaryData]="returnTransferSummaryData"
      [minimumConditions]="minimumConditions"
      [termsAndConditions]="termsAndConditions"
      [productSelectionStepForm]="productSelectionStepForm"
      (buttonClick)="handleButtonClick()"
      (deletePromoCode)="deletePromotionCode()"
    ></app-checkout-container>
    <app-checkout-container
      class="w-100 h-100"
      *ngIf="
        (currentStep === 6 || currentStep === 4 || currentStep === 7) &&
        returnTransferSummaryData.locationInfo?.originAddress
      "
      [summaryData]="summaryData"
      [currentStep]="currentStep"
      [disabledButtonLastStep]="disabledButtonLastStep"
      [buttonText]="buttonText"
      [returnTransferSummaryData]="returnTransferSummaryData"
      [minimumConditions]="minimumConditions"
      [termsAndConditions]="termsAndConditions"
      [productSelectionStepForm]="productSelectionStepForm"
      (buttonClick)="handleButtonClick()"
      (deletePromoCode)="deletePromotionCode()"
    ></app-checkout-container>
  </div>
</ng-container>

<app-modal-minimum-conditions></app-modal-minimum-conditions>
<app-modal-promotion-code
  [(promotionCode)]="promotionCode"
  [promotionCodeValidator]="promotionCodeValidator"
  (validatePromoCode)="validatePromotionCode()"
></app-modal-promotion-code>
<app-modal-cancellation-policy></app-modal-cancellation-policy>
<app-modal-refueling-fee></app-modal-refueling-fee>
