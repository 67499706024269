<div class="header">
  <img
    (click)="onClose()"
    (keyup)="onClose()"
    tabindex="0"
    src="/assets/icons/close-modal.svg"
    alt="closeIcon"
    class="pointer"
  />
</div>
<div class="refill-box" #refillBox>
  <app-alert
    #alertComponent
    [text]="errorAlertText"
    [type]="2"
    [isForDialog]="true"
    *ngIf="showErrorAlert"
    (closeAlertEvent)="onAlertClosed()"
  ></app-alert>
  <div class="title-box">
    <h4>{{ 'serviceInsurance' | translate }}</h4>
    <p>{{ 'coverAnyIncident' | translate }}</p>
  </div>
  <div class="seleccion-importe">
    <h4>{{ 'selectInsuranceDays' | translate }}</h4>
    <div class="prices-box">
      <div *ngFor="let option of options; let i = index">
        <div
          [ngClass]="{ checked: selected === i }"
          (click)="selectOption(i)"
          (keydown)="selectOption(i)"
          tabindex="0"
        >
          <span class="price">{{ option.Days }} día</span>
          <span class="subtitle">{{ option.Price }}{{ 'currency_symbol' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="insuranceForm" class="w-100">
    <div class="form-group">
      <label for="nombre">{{ 'name' | translate }}</label>
      <input
        type="text"
        id="nombre"
        formControlName="nombre"
        placeholder="Dani"
        [ngClass]="{
          'error-input': invalidForm && !insuranceForm.get('nombre')?.valid,
        }"
      />
      <div *ngIf="insuranceForm.get('nombre')?.invalid && invalidForm" class="error">
        {{ 'required' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label for="apellidos">{{ 'last_name' | translate }}</label>
      <input
        type="text"
        id="apellidos"
        formControlName="apellidos"
        placeholder="Parrales Rosa"
        [ngClass]="{
          'error-input': invalidForm && !insuranceForm.get('apellidos')?.valid,
        }"
      />
      <div *ngIf="insuranceForm.get('apellidos')?.invalid && invalidForm" class="error">
        {{ 'required' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label for="tipo">{{ 'documentType' | translate }}</label>
      <select
        id="tipo"
        formControlName="tipo"
        [ngClass]="{
          'error-input': invalidForm && !insuranceForm.get('tipo')?.valid,
        }"
      >
        <option value="dni">{{ 'dni' | translate }}</option>
        <option value="nie">{{ 'nie' | translate }}</option>
        <option value="pasaporte">{{ 'passport' | translate }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="numero">{{ 'documentNumber' | translate }}</label>
      <input
        type="text"
        id="numero"
        formControlName="numero"
        placeholder="12345678A"
        [ngClass]="{
          'error-input': invalidForm && !insuranceForm.get('numero')?.valid,
        }"
      />
      <div *ngIf="insuranceForm.get('numero')?.invalid && invalidForm" class="error">
        {{ 'required' | translate }}
      </div>
    </div>
  </form>
  <div class="footer">
    <button (click)="addTopping()">{{ 'add' | translate }}</button>
  </div>
</div>
