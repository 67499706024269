import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { SummaryData, ToppingInformation } from 'src/app/pages/new-service/interfaces/SummaryData';
import { MappedTopping } from 'src/app/pages/new-service/interfaces/Topping';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-modal/confirm-dialog.component';

export interface availabilitySlots {
  Key: number;
  Date: Date;
  Price: number;
  Disabled: boolean;
}

export interface availabiltyResponse {
  productId?: string;
  AvailabilitySlots?: availabilitySlots[];
}

@Component({
  selector: 'app-modal-extra-sustitution-vehicle',
  templateUrl: './modal-extra-sustitution-vehicle.component.html',
  styleUrls: ['./modal-extra-sustitution-vehicle.component.scss'],
})
export class ModalExtraSustitutionVehicleComponent implements OnInit {
  @ViewChild('section') section!: ElementRef;
  @ViewChild('alertComponent') alertComponent!: ElementRef;
  options: availabilitySlots[] = [];
  sustitutionForm: FormGroup;
  summaryData: SummaryData;
  sustitutionTopping: MappedTopping;
  invalidForm = false;
  showErrorAlert = false;
  errorAlertText = '';

  constructor(
    private fb: FormBuilder,
    private funnelService: FunnelService,
    private loader: LoaderService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalExtraSustitutionVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { summaryData: any; mappedTopping: MappedTopping }
  ) {
    this.summaryData = data.summaryData;
    this.sustitutionTopping = data.mappedTopping;
    this.sustitutionForm = this.fb.group({
      returnDate: ['', Validators.required],
      contactName: ['', Validators.required],
      contactLastName: ['', Validators.required],
      phoneRegion: ['esp', Validators.required],
      contactPhone: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      contactEmail: ['', [Validators.required, Validators.email]],
    });
    this.sustitutionForm.get('phoneRegion')?.valueChanges.subscribe((value) => {
      if (!value) this.setPhoneValidators('contactPhone', 'esp');
      this.setPhoneValidators('contactPhone', value);
    });
  }

  async ngOnInit(): Promise<void> {
    await this.funnelService
      .getReplacementVehicleAvailabilities(
        this.summaryData.productInformation.ZoneId as string,
        new Date(),
        'basic'
      )
      .subscribe((options: availabiltyResponse) => {
        this.options = options.AvailabilitySlots || [];
      });
  }

  onClose(): void {
    const confirmationDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'sure_you_wanna_leave',
        message: 'disclousure_message_confirmation_dialog',
        confirmText: 'confirm',
        cancelText: 'cancel',
      },
    });

    confirmationDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.closeMainDialog();
      }
    });
  }

  private closeMainDialog(): void {
    if (this.sustitutionForm.valid) {
      const result = {
        serviceConfiguration: this.getServiceConfiguration(),
        addedTopping: this.getAddedTopping(),
      };
      this.dialogRef.close(result);
    } else {
      this.dialogRef.close({ incompletedTopping: true });
    }
  }

  getServiceConfiguration() {
    const formValue = this.sustitutionForm.value;
    return {
      AmountOfDays: formValue.returnDate.Key,
      VehicleCategory: 'basic',
      ContactName: formValue.contactName,
      ContactLastName: formValue.contactLastName,
      ContactPhone: formValue.contactPhone,
      ContactEmail: formValue.contactEmail,
    };
  }

  getAddedTopping(): ToppingInformation {
    const formValue = this.sustitutionForm.value;
    const selectedOption = this.options.find((option) => option.Key === formValue.returnDate.Key);
    return {
      title: 'replacement_vehicle',
      description: 'replacement_vehicle',
      price: selectedOption ? selectedOption.Price : 0,
      quantity: formValue.returnDate.Key,
      icon: 'assets/icons/sustitution-vehicle.svg',
    };
  }

  addTopping() {
    if (this.sustitutionForm.valid) {
      this.loader.show();
      this.funnelService
        .addTopping(
          this.sustitutionTopping.products[0].id,
          this.summaryData.orderHash as string,
          this.getServiceConfiguration(),
          'replacement-vehicle'
        )
        .subscribe({
          next: (response) => {
            this.loader.hide();
            this.dialogRef.close(response);
          },
          error: (error) => {
            this.loader.hide();
            this.showErrorAlert = true;
            this.errorAlertText = 'No se pudo añadir el topping. Por favor, inténtelo de nuevo.';
            console.error('Error añadiendo topping:', error);
            this.scrollToTop();
          },
        });
    } else {
      this.invalidForm = true;
    }
  }
  phoneNumberValidator(country: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      let isValid: boolean;

      switch (country.toLowerCase()) {
        case 'esp':
          isValid = /^[0-9]{9}$/.test(value);
          break;
        case 'gbr':
          isValid = /^[0-9]{10}$/.test(value);
          break;
        case 'fra':
          isValid = /^[0-9]{10}$/.test(value);
          break;
        default:
          isValid = true;
      }

      return isValid ? null : { invalidPhoneFormat: { value: control.value } };
    };
  }

  setPhoneValidators(phoneField: string, country: string): void {
    const control = this.sustitutionForm.get(phoneField);
    if (control) {
      control.clearValidators();
      control.setValidators([Validators.required, this.phoneNumberValidator(country)]);
      control.updateValueAndValidity();
    }
  }

  public getErrorMessage(controlName: string, formGroupName = ''): string {
    let control;
    if (formGroupName) {
      control = this.sustitutionForm.get(formGroupName)?.get(controlName);
    } else {
      control = this.sustitutionForm.get(controlName);
    }

    if (control?.hasError('required')) {
      return this.translate.instant('required');
    }

    if (control?.hasError('invalidPhoneFormat')) {
      return 'Formato de teléfono incorrecto';
    }

    return '';
  }

  onAlertClosed(): void {
    this.showErrorAlert = false;
  }
  scrollToTop() {
    setTimeout(() => {
      if (this.section && this.alertComponent) {
        this.section.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        // Añadir una clase para la animación de resaltado
        this.alertComponent.nativeElement.classList.add('highlight');

        // Remover la clase después de la animación
        setTimeout(() => {
          this.alertComponent.nativeElement.classList.remove('highlight');
        }, 1500); // Duración de la animación
      }
    });
  }
}
