import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProduct } from './interfaces/product.interface';
import { InfoModalService } from './services/info-modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  @Input() productKey!: string;
  productInfo!: IProduct | null;
  headerText: string | undefined;
  routesConfig = this.router.config;
  combinedIncludingDescriptions = '';

  constructor(
    private router: Router,
    private infoModal: InfoModalService,
    private dialogRef: MatDialogRef<InfoModalComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { productKey: string }
  ) {}

  async ngOnInit() {
    this.headerText = this.routesConfig.find(
      (e) => e.path === this.router.url.split('/')[1]
    )?.data?.['title'];
    this.productInfo = this.infoModal.getProduct(this.data.productKey);
    if (this.productInfo?.includingDescriptions) {
      const includingDescriptions = this.productInfo.includingDescriptions.map(() =>
        this.translate.instant(this.productInfo?.includingDescriptions as string[])
      );
      this.combinedIncludingDescriptions = includingDescriptions.join('');
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
